import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, Divider, Grid, MuiThemeProvider, TextField, Typography, withStyles } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import { Autocomplete, Rating } from '@material-ui/lab/';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SlihomeTheme from '../../Models/SlihomeMuiTheme';
import Scrollbars from 'react-custom-scrollbars';
import { IMAGE_PURPOSE, SlihomeAPIClient } from '../../Models/SlihomeAPI';
import { store } from 'react-notifications-component';
import { LS } from '../../Models/SlihomeLocalStorage';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const ReviewForm = ({
    roomInfo,
    onFinish,
    ...props
}) => {
    const [rating, setRating] = useState(2.5);
    const [comment, setComment] = useState("");
    const [tags, setTags] = useState([]);
    const [commentError, setCommentError] = useState(false);
    const [hover, setHover] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    const [locationTags, setLocationTags] = useState([]);
    const [generalTags, setGeneralTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        Promise.all([LS.getLocationTags(), LS.getGeneralTags()]).then(response => {
            setLocationTags(response[0]);
            setGeneralTags(response[1]);
        })
    }, [])

    useEffect(() => {
        let suggestions = [];
        Object.keys(generalTags).forEach(ele => {
            suggestions.push({
                id: `general@${ele}`,
                name: generalTags[ele]
            });
        })
        Object.keys(locationTags).forEach(ele => {
            suggestions.push({
                id: `location@${ele}`,
                name: `Gần ${locationTags[ele]}`
            });
        });
        setSuggestions(suggestions)
    }, [locationTags, generalTags])

    async function postReviewHandler() {
        if (comment.trim().length < 10) {
            setCommentError(true);
        }
        else {
            try{
                setLoading(true);
                let imgRes = await Promise.all(images.map(ele =>
                    SlihomeAPIClient.uploadImage({
                        purpose: IMAGE_PURPOSE.REVIEW,
                        fileName: ele.fileName,
                        content: ele.content
                    })
                ))
                SlihomeAPIClient.postReview({
                    roomID: roomInfo.SK,
                    rating: rating,
                    comment: comment.trim(),
                    ...generateTagFilter(tags),
                    pictures: imgRes.map(ele=> ele.data.Location)
                }).then(response => {
                    if(response.data.Success){
                        store.addNotification({
                            title: "Thành công",
                            message:
                                `Đã đăng thành công đánh giá`,
                            type: "success",
                            insert: "top",
                            container: "bottom-left",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        onFinish()
                    }
                    else {
                        store.addNotification({
                            title: "Lỗi",
                            message:
                                `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                            type: "danger",
                            insert: "top",
                            container: "bottom-left",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }).catch(error => {
                    console.error(error)
                    store.addNotification({
                        title: "Lỗi",
                        message:
                            `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                        type: "danger",
                        insert: "top",
                        container: "bottom-left",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }).finally(() => {
                    setLoading(false);
                })
            } catch (err){
                setLoading(false);
                store.addNotification({
                    title: "Lỗi tải ảnh lên",
                    message:
                        `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                    type: "danger",
                    insert: "top",
                    container: "bottom-left",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                console.error(err)
            }
        }
    }

    function onComment(event) {
        setCommentError(false);
        setComment(event.target.value);
    }

    function onDrop(picture, data) {
        let picList = [];
        picture.forEach((file,index)=>{
            picList.push({
                fileName: file.name,
                content: data[index].split(',')[data[index].split(',').length-1]
            })
        });
        setImages(picList);
    }

    function tagOnChange(event, value){
        setTags(value);
    }

    function generateTagFilter(tagList = []) {
        let general = [];
        let location = [];
        tagList.forEach(ele => {
            let tagType = ele.id.split("@")[0];
            let tagIndex = parseInt(ele.id.split("@")[1]);
            switch (tagType) {
                case "general":
                    general.push(tagIndex);
                    break;
                case "location":
                    location.push(tagIndex);
                    break;
                default:
            }
        })
        let generalTag = Array.apply(null, Array(Object.keys(generalTags).length)).map((ele, index) => general.indexOf(index) < 0 ? 0 : 1).join(',');
        let locationTag = Array.apply(null, Array(Object.keys(locationTags).length)).map((ele, index) => location.indexOf(index) < 0 ? 0 : 1).join(',');
        return {
            generalTag: generalTag,
            locationTag: locationTag
        }
    }

    return (
        <MuiThemeProvider theme={SlihomeTheme}>
            <Grid container spacing={2} justify="center" alignContent="center" style={{minHeight: '90vh'}} direction="column">
                <Grid item>
                    <Typography variant="h5" style={{ textAlign: "center", fontWeight: 600, color: "#999999", paddingBottom: "1rem" }}>
                        Viết đánh giá
                    </Typography>
                    <Divider />
                    <Typography variant="h6" style={{ textAlign: "center", fontWeight: 600, color: "#ea285e", paddingTop: "1rem" }}>
                        {roomInfo.RoomName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container justify="center" alignContent="center" item>
                        <StyledRating
                            color="primary"
                            size="large"
                            name="rating-review"
                            defaultValue={2.5}
                            value={rating}
                            precision={0.5}
                            icon={<FavoriteIcon fontSize="inherit" />}
                            onChange={(ev, newVal) => {
                                setRating(newVal)
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    {rating !== null && <Typography style={{ textAlign: "center", fontWeight: 600, color: "#373737" }}>
                        {labels[hover !== -1 ? hover : rating]}
                    </Typography>}
                </Grid>
                <Grid item>
                    <TextField
                        required
                        error={commentError}
                        value={comment}
                        onChange={onComment}
                        style={{ width: "100%" }}
                        label="Bình luận"
                        helperText="Tối thiểu 10 ký tự không tính khoảng trắng"
                        multiline
                        rows={4}
                        placeholder="Nhập vào bình luận (Tối đa 500 ký tự)"
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        size="small"
                        multiple
                        limitTags={2}
                        id="review-filter-tags"
                        options={suggestions}
                        onChange={tagOnChange}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            {option.name}
                        </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Tìm theo tag" placeholder="Thêm tag" />
                        )}
                    />
                </Grid>
                <Grid item style={{ width: "100%", flex: 1 }}>
                    <Scrollbars autoHeight>
                        <ImageUploader
                            buttonText='Chọn hình ảnh'
                            onChange={onDrop}
                            imgExtension={['.jpg', '.gif', '.png']}
                            buttonStyles={{ paddingTop: '200px', fontWeight: '600', opacity: 0, marginTop: '-20%', position: 'absolute', paddingBottom: '100px' }}
                            maxFileSize={5242880}
                            withPreview
                        />
                    </Scrollbars>
                </Grid>
                <Grid container spacing={2} justify="center" alignContent="center" item>
                    <Grid item xs={5}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="default"
                            className="cancel-btn"
                            style={{
                                "borderRadius": "10rem",
                                "height": "3rem",
                                "fontWeight": "bolder",
                                "fontFamily": "Open Sans",
                                "fontSize": "16px"
                            }}
                            onClick={onFinish}
                            disabled={loading}
                        >
                            HỦY
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            type="button"
                            id="submitButton"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="submit-btn"
                            style={{
                                "borderRadius": "10rem",
                                "height": "3rem",
                                "fontWeight": "bolder",
                                "fontFamily": "Open Sans",
                                "fontSize": "16px"
                            }}
                            onClick={postReviewHandler}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress style={{ color: "#FFFFFF" }} /> : "GỬI"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    )
}

export default ReviewForm;