import { useState } from "react"
import { Col, OverlayTrigger, Row, Spinner, Tooltip, Button } from "react-bootstrap"
import Scrollbars from "react-custom-scrollbars"
import { store } from "react-notifications-component"
import { SlihomeAPIClient } from "../../Models/SlihomeAPI"
import { Link } from "@material-ui/core";
import { PATHS } from "../../Const/index";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const SlihomeTextInfo = ({data, ...props}) => {
    const [lessorInfo, setLessorInfo] = useState()
    const [loadLessorInfo, setLoadLessorInfo] = useState(false)

    const getLessorInfo = () => {
		setLoadLessorInfo(true)
        SlihomeAPIClient.increasePopulation({
            roomID: data.SK // RoomID is SK in database (Not PK)
        })
		SlihomeAPIClient.getPublicUserDetail({
			targetUsername: data.PK.split("#")[2]
		}).then((response) => {
			let data = response.data;
			setLessorInfo({
                phoneNumber: data.Username,
                name: data.UserAttributes.find(ele => ele.Name === "name") ? data.UserAttributes.find(ele => ele.Name === "name").Value : undefined,
                email: data.UserAttributes.find(ele => ele.Name === "email") ? data.UserAttributes.find(ele => ele.Name === "email").Value : undefined,
            })
		}).catch((err) => {
			console.error(err.response);
			store.addNotification({
				title: err?.response?.data ? err?.response?.data?.message || "Không lấy được số điện thoại" : "Không lấy được số điện thoại",
				message: "Xảy ra lỗi trong quá trình lấy số điện thoại của chủ trọ, vui lòng thử lại. Nếu lỗi này xảy ra liên tiếp, vui lòng liên hệ quản trị viên thông qua fanpage",
				type: "danger",
				insert: "top",
				container: "bottom-left",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true
				}
			});
		}).finally(() => {
			setLoadLessorInfo(false)
		})
	}

    return (
        <div>
            <Row className='card-header'>
                <Col xs={9}>
                <Link href={getRoomDetailUrl(data?.SK)} style={{textDecoration: "none", padding: 0}} target='_blank'>
											<span className="card-title">{data?.AdvertiseTitle}</span>
											<sup style={{fontSize: "15px"}}><OpenInNewIcon fontSize="inherit" /></sup>
										</Link>

                </Col>
                <Col xs={3}>
                    <span className="card-text card-allowanceInfo card-right">
                        {
                            data.MaxSlots > 0 ?
                                <div className="gradient-text card-currentSlot">{data.MaxSlots - data.CurrentSlot}</div>
                                : ""
                        }
                        {checkGenderAllowance(data.GenderAllowance)}
                    </span>
                </Col>
            </Row>
            <Row className="card-body card-info">
                <Col>
                    <div className="card-cost gradient-text"> {numberWithCommas(data.Cost)} VND</div>
                </Col>
                {/* <div className="col-xl-5">
										<div className="price-container original-price"> <span id="original-price">0</span> VND </div>
									</div> */}
            </Row>
            <Row>
                <Col>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Mô tả</Tooltip>}
                    >
                        <div className="card-text card-info">
                            <img src="/icon/paper-color.svg" className="view-icon" alt="paper-icon" />
                            <span className="card-text">Mô tả: {data.Description}</span>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Địa chỉ chính xác có thể sai lệch so với địa chỉ đánh dấu trên bản đồ</Tooltip>}
                    >
                        <div className="card-text card-info">
                            <img src="/icon/path-931.svg" className="view-icon" alt="location-icon" />
                            <span className="card-text">{data.Address}</span>
                        </div>
                    </OverlayTrigger>
                </Col>
                <Col xs={4}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Kích thước nhà</Tooltip>}
                    >
                        <div className="card-text card-info card-right">
                            <img src="/icon/path-1141.svg" className="view-icon" alt="info-icon" />
                            <span className="card-text">{data.Area} &#13217;</span>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
            {
                data.Floor > 0 ?
                    <Row>
                        <Col>
                            <div className="card-text card-info">
                                <img src="/icon/home2tang.svg" className="view-icon" alt="floor-icon" />
                                <span className="card-text">Tầng: {data.Floor} </span>
                            </div>
                        </Col>
                    </Row>
                    : ""
            }

            <Row className="card-group-title">
                <Col style={{ padding: "10px 15px"}}>
                    <span>Chi phí phát sinh và thông tin thanh toán</span>
                </Col>
            </Row>
            <Row>
                {
                    data.ElectricCost >= 0 ?
                        <Col xs={6}>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip>Giá tiền điện</Tooltip>}
                            >
                                <div className="card-text card-info">
                                    <img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
                                    <span className="card-text">{numberWithCommas(data.ElectricCost)} VNĐ / số điện</span>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        : ""
                }
                {
                    data.InternetCost >= 0 ?
                        <Col xs={6}>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip>Tiền mạng internet</Tooltip>}
                            >
                                <div className="card-text card-info card-right">
                                    <img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
                                    <span className="card-text">Internet: {numberWithCommas(data.InternetCost)} VNĐ / tháng</span>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        : ""
                }
            </Row>
            <Row>
                {
                    data.WaterCost >= 0 ?
                        <Col>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip>Giá tiền nước</Tooltip>}
                            >
                                <div className="card-text card-info">
                                    <img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
                                    <span className="card-text">{numberWithCommas(data.WaterCost)} VNĐ / số nước</span>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        : ""
                }
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="card-text card-info">
                        <img src="/icon/month-color.svg" className="view-icon" alt="calendar-icon" />
                        <span className="card-text">Thanh toán vào {data.PaymentDate <= 10 ? `mùng ${data.PaymentDate}` : data.PaymentDate} hàng tháng</span>
                    </div>
                </Col>
            </Row>
            <Row className="card-group-title">
                <Col style={{ padding: "10px 15px"}}>
                    <span>Chi tiết và thông tin liên hệ</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Thông tin thêm</Tooltip>}
                    >
                        <div className="card-text card-info">
                            <span>
                                <img src="/icon/note.svg" className="view-icon" alt="exclaimation icon" />
                                <span className="card-text">Thông tin thêm: {data.Note ? data.Note : "Không có"} </span>
                            </span>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="card-text card-info">
                        <span>
                            {lessorInfo ?
                                <div>
                                    {lessorInfo.name ?
                                        <Row>
                                            <Col>
                                                <div className="card-text card-info">
                                                    <img src="/icon/user.svg" className="view-icon" alt="user-icon" />
                                                    <span className="card-text">Họ và tên: {lessorInfo.name} </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        : ""
                                    }
                                    <Row>
                                        <Col>
                                            <div className="card-text card-info">
                                                <img src="/icon/telephone.svg" className="view-icon" alt="phone-icon" />
                                                <span className="card-text">Số điện thoại: {lessorInfo.phoneNumber} </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        lessorInfo.email ?
                                            <Row>
                                                <Col>
                                                    <div className="card-text card-info">
                                                        <img src="/icon/email.svg" className="view-icon" alt="email-icon" />
                                                        <span className="card-text">Email: {lessorInfo.email} </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : ""
                                    }

                                </div>
                                :
                                loadLessorInfo ?
                                    <Row>
                                        <Col>
                                            <Spinner animation="border" variant="danger" size="sm" />
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col>
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip>Bấm vào đây để lấy số điện thoại của chủ trọ</Tooltip>}
                                            >
                                                <Button variant="outline-danger" onClick={getLessorInfo} size="sm">Xem chi tiết</Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                            }
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SlihomeTextInfo

function numberWithCommas(x) {
	if (x && x.toString instanceof Function)
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function checkGenderAllowance(GenderAllowance) {
	if (GenderAllowance === "Male") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nam</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol.svg" className="card-icon" alt="female-deactive" />
				</div>
			</OverlayTrigger>
		)
	}
	else if (GenderAllowance === "Female") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol.svg" className="card-icon" alt="male-deactive" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>
		)
	}
	else {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Cả nam và nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>
		)
	}
}
function getUrlFormated(originalUrl = "") {
	return originalUrl.replaceAll("#", "%23").replaceAll("+", "%2b").replaceAll("@", "%40");
}

function getRoomDetailUrl(roomID) {
	return `${PATHS.ONE_ROOM}?roomID=${getUrlFormated(roomID)}`}
