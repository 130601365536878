import { Grid, Modal, Button, Fade, Backdrop, makeStyles, Paper, CircularProgress, MuiThemeProvider, Typography, Tooltip, withStyles } from '@material-ui/core';
import { Rating } from  '@material-ui/lab/';
import FavoriteIcon from '@material-ui/icons/Favorite';
import React, { useCallback, useEffect, useState } from 'react';
import SlihomeReviewForm from './SlihomeReviewForm';
import SlihomeReview from './SlihomeReview'
import { SlihomeAPIClient } from '../../Models/SlihomeAPI';
import SlihomeTheme from '../../Models/SlihomeMuiTheme';
import Scrollbars from 'react-custom-scrollbars';

function calRateCount (ratingHistogram) {
    let count = 0;
    Object.keys(ratingHistogram).forEach((key)=>{
        count+=parseInt(ratingHistogram[key])
    })
    return count;
}

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    reviewContainer: {
		position: 'fixed',
		width: "fit-content",
		height: "fit-content",
		top: "calc(50% + 32px)",
		left: "50%",
		transform: "translate(-50%,-50%)",
		borderRadius: "10px",
		backgroundColor: "#FFFFFF",
		zIndex: 10000,
		boxShadow: "0px 10px 20px -5px rgba(0,0,0,0.75)",
		padding: "20px",
	},
}));

const SlihomeReviewList = ({
    roomInfo,
})=>{
    const [reviewList, setReviewList] = useState();
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()
    const [error, setError] = useState();

    const getReviewList = useCallback(()=> {
        SlihomeAPIClient.getReviewList({
            roomID: roomInfo.SK
        }).then((response)=>{
            if(response.data.Success){
                setReviewList(response.data.Result)
            }
        }).catch((err)=>{
            setError(err);
            console.error(err);
        })
    }, [roomInfo.SK])

    useEffect(()=>{
        getReviewList()
    }, [getReviewList])

    

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MuiThemeProvider theme={SlihomeTheme}>
            <Grid container justify="center" direction="column" style={{height: "100%"}}>
                <Grid item container className="card-group-title" style={{padding: "10px 0"}}>
                    <Grid item lg={6}>
                        <span>Chi tiết đánh giá</span>
                    </Grid>
                    <Grid item lg={6}>
                        <div style={{display: "flex", float: "right"}}>
                            <span className="card-text">
                                {roomInfo.Rating!==0?roomInfo.Rating:""}
                            </span>
                            &nbsp;
                            <StyledRating
                                size="small"
                                name={roomInfo.SK}
                                value={roomInfo.Rating}
                                readOnly
                                precision={0.5}
                                icon={<FavoriteIcon fontSize="inherit" />}
                            />
                            &nbsp;
                            <Tooltip title="Số lượt đánh giá" aria-label="add">
                                <span>
                                    (
                                    <span className="card-text">{calRateCount(roomInfo.RatingHistogram)}&nbsp;</span>
                                    <img src="/icon/user.svg" className="card-icon" alt="user-icon" />
                                    )
                                </span>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid item style={{flex: 1}}>
                    <Scrollbars style={{height: "100%"}}>
                        {
                            error?
                                <Typography color="primary">
                                    Có lỗi xảy ra trong quá trình lấy dữ liệu.
                                </Typography>
                                :
                                reviewList?
                                    reviewList.length===0?
                                        <Typography>
                                            Không có đánh giá nào
                                        </Typography>
                                        :
                                        reviewList.map(review=><SlihomeReview reviewContext={review} key={review.SK}/>)
                                    :
                                    <div style={{width: "100%", display: "flex", margin: "1rem"}}>
                                        <CircularProgress size="2rem" style={{margin: "auto"}}/>
                                    </div>
                        }
                    </Scrollbars>
                </Grid>
                <Grid item style={{width: "100%", margin: "1rem auto auto auto", textAlign: "center"}}>
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={handleOpen}
                        className="button-review"
                    >
                        Viết đánh giá
                    </Button>
                </Grid>
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Paper elevation={3} className={classes.reviewContainer}>
                            <SlihomeReviewForm roomInfo={roomInfo} onFinish={()=>{
                                getReviewList();
                                handleClose();
                            }}/>
                        </Paper>
                    </Fade>
                </Modal>
            </Grid>
        </MuiThemeProvider>
    )
}

export default SlihomeReviewList