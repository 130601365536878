import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { LS } from '../../Models/SlihomeLocalStorage';

const SlihomeTagsHolder = ({
    locationTags,
    generalTags
})=>{
    const [locationTagsDict, setLocationTagsDict] = useState([]);
    const [generalTagsDict, setGeneralTagsDict] = useState([]);

    useEffect(() => {
        Promise.all([LS.getLocationTags(), LS.getGeneralTags()]).then(response => {
            setLocationTagsDict(response[0]);
            setGeneralTagsDict(response[1]);
        })
    }, [])

    if(!(locationTags && generalTags && locationTagsDict.length!==0 && generalTagsDict.length!==0)) return null;

    return (
        <Box>
            {locationTags.split(',').map((ele,index)=>
                ele==="1"?locationTagsDict[index]:null
            ).filter((ele)=>
                ele!==null?true:false
            ).map((tag)=>
                <Typography key={tag} className="tag-holder" style={{fontSize: "0.8rem", marginRight: "0.2rem"}}>
                    Gần {tag}
                </Typography>
            )}
            {generalTags.split(',').map((ele,index)=>
                ele==="1"?generalTagsDict[index]:null
            ).filter((ele)=>
                ele!==null?true:false
            ).map((tag)=>
                <Typography key={tag} className="tag-holder" style={{fontSize: "0.8rem", marginRight: "0.2rem"}}>
                    {tag}
                </Typography>
            )}
        </Box>
    )
}

export default SlihomeTagsHolder;