import { createMuiTheme } from "@material-ui/core";

const SlihomeTheme = createMuiTheme({
    palette: {
      primary: {
        main:  "rgb(238, 45, 82)",
      },
      background: {
        default: "#fff",
      }
    },
    typography: {
      fontFamily: [
        "Open Sans",
      ]
    }
});

export default SlihomeTheme