import {
    useState
} from "react";

import {
    Grid,
    withStyles,
    TextField,
    Link,
    Avatar,
    Typography,
    Box,
    Paper
} from "@material-ui/core"

import FavoriteIcon from '@material-ui/icons/Favorite';

import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';

import {
    Rating
} from '@material-ui/lab/';

import Scrollbars from 'react-custom-scrollbars';

import SlihomeTagsHolder from "../../SHMainApp/Components/SlihomeTagsHolder";
import { PATHS } from "./../../Const/index";

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const SHOneRoomReview = ({ reviewInfo }) => {

    const [open, setOpen] = useState(false);
    const [previewList, setPreviewList] = useState(reviewInfo["Pictures"]||[]);

    const handleOpen = (event) => {
        if(reviewInfo["Pictures"] instanceof Array)
            setPreviewList([...reviewInfo["Pictures"]].sort(function(x,y){ return x === event.target.src ? -1 : y === event.target.src ? 1 : 0; }))
        setOpen(true);
    };
    

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item container xs={12}>
            <Grid item container className="post-review-content-avatar" xs={12} sm={3} lg={2} xl={1} direction="column" alignItems="center" justify="center" spacing={2}>
                <Grid item xs>
                    <Link href={`${PATHS.USER_PROFILE}?username=${reviewInfo["PK"].split('#')[2]}`} style={{ maxWidth: "100%", height: "auto" }} >
                        <Avatar alt={reviewInfo["Name"]} src={reviewInfo["Picture"]} />
                    </Link>
                </Grid>
                <Grid item xs container justify="center">
                    <Typography variant="h6" style={{ textAlign: "center", fontSize: "1em" }}>
                        <Link href={`${PATHS.USER_PROFILE}?username=${reviewInfo["PK"].split('#')[2]}`} style={{ maxWidth: "100%", height: "auto" }} >
                            {reviewInfo["Name"]}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <StyledRating
                        color="primary"
                        size="small"
                        name="rating-review"
                        value={reviewInfo["Rating"]}
                        precision={0.5}
                        icon={<FavoriteIcon fontSize="inherit" />}
                        readOnly
                    />
                </Grid>
            </Grid>
            <Grid item xs sm lg xl container direction="column" style={{ flexWrap: "wrap" }} spacing={3}>
                <Grid item xs>
                    <TextField
                        value={reviewInfo["Comment"]}
                        style={{ width: "auto" }}
                        label="Bình luận"
                        multiline
                    />
                </Grid>
                <Grid item xs>
                    <SlihomeTagsHolder generalTags={reviewInfo["Gen"]} locationTags={reviewInfo["Loc"]} />
                </Grid>
                {
                    reviewInfo["Pictures"] instanceof Array ?
                        <Grid item style={{ height: "5rem" }}>
                            <Scrollbars style={{ width: "100%", height: "100%" }}>
                                <Box style={{ height: "95%", display: "inline-flex" }}>
                                    {reviewInfo["Pictures"].map((tile) => (
                                        <img key={tile} src={tile} alt="Ảnh review" style={{ display: "inline-block", height: "100%", borderRadius: "5px" }} onClick={handleOpen} />
                                    ))}
                                </Box>
                            </Scrollbars>
                        </Grid>
                        :
                        null
                }
                <Grid item>
                    <Box fontStyle="italic">
                        {getTimeAgo(reviewInfo["SK"])}
                    </Box>
                </Grid>
            </Grid>
            {reviewInfo["Pictures"] instanceof Array ?
                    <AutoRotatingCarousel
                        open={open}
                        autoplay={false}
                        onClose={handleClose}
                        onStart={handleOpen}
                        style={{ position: 'absolute' }}
                    >
                        {
                            previewList.map((tile) => (
                                <Paper key={tile} style={{width: "100%", height: "100%", display: "flex", backgroundColor: "rgb(150,150,150)"}}>
                                    <img src={tile} alt="Ảnh review" style={{margin: "auto", maxHeight: "100%", maxWidth: "100%"}} />
                                </Paper>
                            ))
                        }
                    </AutoRotatingCarousel>
                    : null
                }
        </Grid>
    )
}

export default SHOneRoomReview

function getTimeAgo(SK) {
    try {
        return timeSince(new Date(getReviewTime(SK)));
    }
    catch (err) {
        return "";
    }
}


function getReviewTime(SK) {
    return new Date(parseInt(SK.split("#").pop()));
}

function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " năm trước";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " tháng trước";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " ngày trước";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " giờ trước";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " phút trước";
    }
    return "vừa xong";
}
