import React from 'react';
import { Helmet } from 'react-helmet';
import './SHTermService.css';
import SHFooter from '../SHFooter/SHFooter';

const SHTermService = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Điều khoản dịch vụ Slihome</title>
                <meta name="description" content="Chi tiết các điều khoản khi người dùng sử dụng dịch vụ tìm trọ Slihome" />
            </Helmet>
            <div id="page" className="site">
                <div className="term-service-main container">
                    <h1 className="text-content">ĐIỀU KHOẢN SỬ DỤNG</h1>

                    <div className="item-term-service">
                        <p className="title-term-service">1. GIỚI THIỆU</p>
                        <p>Chào mừng bạn đến với hệ thống Sli Home. Mục đích chính của nền tảng này là cung cấp nền tảng trực tuyến nhằm cung 
                            cấp kết nối người cho thuê nhà trọ và những người đang tìm thuê.</p>
                        <p>Các tổ chức, hay cá nhân đã, đang và sẽ tham gia giao dịch trên nền tảng Sli Home, sẽ sẵn sàng tham gia giao dịch 
                            với một thái độ tôn trọng quyền và lợi ích của nhau, một cách hợp pháp nhờ có các hợp đồng và không trái với các 
                            quy định của pháp luật.</p>
                        <p>Chúng tôi cung cấp thông tin trong các bài đăng trên nền tảng Sli Home với mục đích hỗ trợ 
                            kết nối thực hiện mong muốn thuê chỗ ở của người đi thuê và chủ nhà trọ.</p>
                        <p>Các thông tin đã được đăng trên Sli Home cần phải được tuân thủ theo tất cả các luật áp dụng (nếu có), 
                            không đăng những thông tin hoặc sản phẩm bị cấm bởi pháp luật.</p>
                        <p>Chúng tôi cũng hoạt động như một kênh giao tiếp giữa Chủ Nhà và Khách hàng để có thể giải quyết bất kỳ 
                            tranh chấp nào giữa hai bên.</p>
                        <p>Việc sử dụng nền tảng cần phải được thực hiện một cách công khai và minh bạch để đảm bảo quyền lợi và 
                            sự an toàn của các bên.</p>
                        <p>Các bên chịu trách nhiệm cho tất cả các chi phí liên quan với việc sử dụng nền tảng (nếu có).</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">2. PHẠM VI ÁP DỤNG</p>
                        <p>Điều khoản sử dụng này áp dụng cho nền tảng cho chủ trọ (<a href="www.host.slihome.com">www.host.slihome.com</a> và ứng dụng Sli Home Host), 
                            nền tảng cho người đi thuê tọ (<a href="www.slihome.com">www.slihome.com</a> và ứng dụng Sli Home). Khi sử dụng Website này và
                             bất kỳ dịch vụ nào tại đây có nghĩa là Bạn đã chấp nhận và đồng ý tuân theo bản 
                             Điều khoản sử dụng này. Ngoài ra khi sử dụng các Dịch vụ cụ thể của Sli Home, Bạn phải
                              tuân theo các điều khoản và điều kiện riêng áp dụng cho Dịch vụ đó theo từng thời điểm. 
                              Sli Home có thể thay đổi, điều chỉnh Điều khoản sử dụng này, Bạn có thể xem những thông 
                              tin mới cập nhật vào bất cứ lúc nào tại www.slihome.com. Nếu Bạn tiếp tục sử dụng Dịch 
                              vụ có nghĩa là Bạn chấp nhận và đồng ý tuân theo Điều khoản sử dụng mới được cập nhật. 
                              Bất cứ sự vi phạm nào của Bạn đối với các điều khoản và điều kiện này đều có thể dẫn đến
                               việc đình chỉ hoặc kết thúc tài khoản, Dịch vụ hoặc những hoạt động được phép khác theo 
                               Thỏa thuận sử dụng Dịch vụ của Sli Home.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">3. BẢN QUYỀN</p>
                        <p>Sli Home là chủ bản quyền của trang web này. Việc chỉnh sửa trang, nội dung, và sắp xếp thuộc về thẩm quyền của Itro. 
                            Sự chỉnh sửa, thay đổi, phân phối hoặc tái sử dụng những nội dung trong trang này vì bất kì mục đích 
                            nào khác được xem như vi phạm quyền lợi hợp pháp của Sli Home.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">4. THÔNG TIN KHÁCH HÀNG</p>
                        <p>Chúng tôi sẽ không sử dụng thông tin cá nhân của bạn trên hệ thống này nếu không được phép. 
                            Nếu bạn đồng ý cung cấp thông tin cá nhân, bạn sẽ được bảo vệ. Thông tin của bạn sẽ được 
                            sử dụng với mục đích, liên lạc với bạn để thông báo các thông tin cập nhật của Sli Home, 
                            các chương trình khuyến mại qua email hoặc bưu điện. Thông tin cá nhân của bạn sẽ không 
                            được gửi cho bất kỳ ai sử dụng ngoài hệ thống Sli Home, ngoại trừ những mở rộng cần thiết 
                            để bạn có thể tham gia vào trang web (những nhà cung cấp dịch vụ, đối tác, các công ty quảng cáo) 
                            và yêu cầu cung cấp bởi luật pháp. Nếu chúng tôi chia sẻ thông tin cá nhân của bạn cho các nhà cung 
                            cấp dịch vụ, công ty quảng cáo, các công ty đối tác liên quan, thì chúng tôi cũng yêu cầu họ bảo 
                            vệ thông tin cá nhân của bạn như cách chúng tôi thực hiện.</p>
                        <p>Bạn phải có trách nhiệm lưu giữ thông tin truy cập vào Sli Home để tránh trường hợp tài khoản bị đánh cắp và/ 
                            hoặc bị lạm dụng với mục đích không an toàn cho cả Bạn và Sli Home. Sli Home không chịu trách nhiệm đối 
                            với sự xâm nhập trái phép của bên thứ ba vào Sli Home của Bạn do sự bất cẩn của Bạn. Sli Home khuyến cáo 
                            Bạn đổi mật khẩu sau khi cung cấp mật khẩu cho các bên liên quan nhằm mục đích hỗ trợ sử dụng. Ngoài ra, 
                            Sli Home cam kết giữ kín mọi thông tin của Bạn khi sử dụng các Dịch vụ trên Website của Sli Home và không 
                            tiết lộ cho bên thứ ba. Sli Home chỉ thay đổi thông tin của Bạn trên Website và Ứng dụng Sli Home khi có 
                            sự đồng ý hoặc yêu cầu của Bạn.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">5. TRÁCH NHIỆM</p>
                        <p>Sli Home sẽ không chịu bất kỳ trách nhiệm nào hoặc trách nhiệm liên đới đối với những hậu quả của việc truy 
                            cập trái phép đến máy chủ, Website, Ứng dụng Sli Home, App Sli Home trang thiết bị và dữ liệu của Bạn hoặc
                             dữ liệu khách hàng của Bạn vì tai nạn, phương tiện bất hợp pháp, thiết bị của Bên thứ ba và các nguyên 
                             nhân khác nằm ngoài sự kiểm soát của Sli Home.</p>
                        <p>Như một điều kiện khi sử dụng Website này và Dịch vụ, Bạn đồng ý rằng Sli Home, nhân viên, các tổ chức thành viên, 
                            cổ đông, đại lý, nhà cung cấp của Sli Home sẽ không chịu trách nhiệm với Bên thứ ba về các thiệt hại về lợi 
                            nhuận, cơ hội kinh doanh; thiệt hại, chi phí phát sinh trực tiếp hay gián tiếp vì kết nối với Website này 
                            hay sử dụng các Dịch vụ của Sli Home.</p>
                        <p>Sli Home sẽ không chịu bất kỳ trách nhiệm nào hoặc trách nhiệm liên đới đối với chất lượng sản phẩm, dịch vụ, 
                            thông tin của các website của Bên thứ ba có liên kết với Website www.slihome.com. Ngoài ra, Bạn cũng hiểu rằng, 
                            Sli Home không có trách nhiệm và thẩm quyền xác nhận, chứng nhận thông tin và chất lượng dịch vụ, sản phẩm hay
                             bồi thường các thiệt hại liên quan đến việc Bạn sử dụng dịch vụ, sản phẩm được quảng cáo trên website đó. 
                             Sli Home khuyến nghị Bạn tìm hiểu đối tác kỹ càng trước khi xúc tiến hợp tác để tránh các thiệt hại không
                              mong muốn. Sli Home không bảo đảm cũng như không chịu trách nhiệm về kết quả kinh doanh của Bạn sau khi 
                              sử dụng dịch vụ.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">6. HƯỚNG DẪN SỬ DỤNG</p>
                        <p>Bạn có thể truy cập và tra cứu tại hướng dẫn tại trang Hướng Dẫn Sử Dụng. Sli Home có thể sẽ không cung 
                            cấp cho Bạn bất kỳ hướng dẫn sử dụng nào dưới dạng văn bản in ấn. Tài liệu hướng dẫn sử dụng được 
                            Sli Home gửi kèm với email xác nhận tài khoản ngay sau khi Bạn đăng ký tài khoản thành công.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">7. QUYỀN ỨNG DỤNG</p>
                        <p>Bạn hiểu và chấp nhận rằng Sli Home có quyền quản lý, khai thác và sử dụng Khu vực chung trên Website 
                            theo quyết định và xem xét duy nhất của Sli Home, mọi yêu cầu của Bạn về sử dụng Khu vực chung trên 
                            Website cho mục đích riêng của Bạn có thể được tính phí ngoài phí dịch vụ qui định trong Hợp đồng.</p>
                        <p>Bạn có toàn quyền sử dụng Khu vực riêng được bảo vệ bằng mật khẩu cho các hoạt động của mình. Sli Home 
                            không can thiệp và không chịu trách nhiệm về các thao tác của Bạn tác động lên dữ liệu và thông tin 
                            trên Khu vực riêng. Mọi yêu cầu phát sinh trong việc sử dụng Khu vực riêng không nằm trong cam kết ban 
                            đầu có thể được tính phí ngoài phí dịch vụ qui định trong Hợp đồng.</p>
                        <p>Bạn hiểu và chấp nhận rằng Sli Home có quyền truy cập khu vực riêng của Bạn khi (i) được Bạn đồng ý nhằm 
                            mục đích hỗ trợ sử dụng, xử lý sự cố (ii) Bạn yêu cầu (iii) trong tình huống khẩn cấp, nhằm ngăn chặn 
                            xâm nhập trái phép hoặc tấn công phá hoại từ bên ngoài.</p>
                        <p>Bạn hiểu và chấp nhận rằng Bạn không có quyền và không có quyền yêu cầu truy cập khu vực riêng của Sli Home, 
                            khu vực quản trị Dịch vụ, Hệ thống và Quản trị website.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">8. ĐIỀU KHOẢN CHUNG</p>
                        <p>Trường hợp có bất kỳ điều khoản nào của Điều khoản sử dụng này hết hiệu lực hoặc không thể thi hành vì bất cứ 
                            lý do gì sẽ chỉ ảnh hưởng đến điều, khoản đã xác định hết hiệu lực đó và không ảnh hưởng đến hiệu lực 
                            của các điều khoản còn lại. Nếu có sự khác biệt giữa Điều khoản sử dụng này và các Thỏa thuận sử dụng 
                            Dịch vụ thì quy định nào mới nhất sẽ có hiệu lực.</p>
                        <p>Nếu bạn tải về máy những phần mềm từ trang này, thì phần mềm và các dữ liệu tải sẽ thuộc bản quyền của Itro 
                            và cho phép bạn sử dụng. Bạn không được sở hữu những phần mềm đã tải và Itro không nhượng quyền cho bạn. 
                            Bạn cũng không được phép bán, phân phối lại, hay bẻ khóa phần mềm…</p>
                        <p>Sli Home giữ quyền thay đổi, chỉnh sửa và loại bỏ những thông tin hợp pháp vào bất kỳ thời điểm nào vì bất 
                            kỳ lý do nào.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">9. ỦY QUYỀN CHO BÊN THỨ BA</p>
                        <p>Mặc dù trang web này có thể được liên kết với những trang khác, Sli Home không trực tiếp hoặc gián tiếp tán thành, 
                            tổ chức, tài trợ, đứng sau hoặc sát nhập với những trang đó, trừ phi điều này được nêu ra rõ ràng. Khi truy 
                            cập vào trang web bạn phải hiểu và chấp nhận rằng Sli Home không thể kiểm soát tất cả những trang liên kết 
                            với Sli Home và cũng không chịu trách nhiệm cho nội dung của những trang liên kết.</p>
                        <p>Bạn hiểu và chấp nhận rằng việc bạn sử dụng dịch vụ gia tăng của Bên thứ ba nằm ngoài những thỏa thuận giữa 
                            Bạn và Sli Home được qui định trong Hợp đồng dịch vụ; và Bạn sẽ tự chịu mọi trách nhiệm liên quan đến việc 
                            sử dụng dịch vụ gia tăng do các Bên thứ ba cung cấp.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">10. LUẬT ÁP DỤNG</p>
                        <p>Mọi hoạt động phát sinh từ hệ thống có thể sẽ được phân tích và đánh giá theo luật pháp Việt Nam. 
                            Và bạn phải đồng 
                            ý tuân theo các điều khoản của pháp luật Việt Nam.</p>
                    </div>
                </div>
	        </div>
            <SHFooter/>
        </div>
    )
}

export default SHTermService
