import React from 'react';
import { Route, Redirect } from 'react-router';
import { PATHS } from '../Const/index';
import Headers from '../SHHeader/SlihomeHeader';

const WrapRouterComponent = ({
    Component,
    isPublic,
    withHeader,
    ...props
}) => {
    const signedIn = localStorage.getItem("authInfo")

    return (
        isPublic||signedIn?
        <Route {...props}>
            {withHeader?<Headers/>:""}
            <Component {...props}/>
        </Route>
        :
        <Redirect to={PATHS.SIGN_IN}/>
    )
}

export default WrapRouterComponent;