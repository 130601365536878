import SlihomeApp from '../SHMainApp/SlihomeApp';
import SlihomeAuth from '../SHSignIn/SlihomeAuth';
import SlihomeSignup from '../SHSignUp/SlihomeSignup';
import SlihomeForgotPassword from '../SHForgotPassword/SlihomeForgotPassword';
import SlihomeUserProfile from '../SHProfile/SlihomeUserProfile';
import SlihomeTermService from '../SlihomeTerm/SHTermService';
import SlihomeNotFound from '../SHError/SlihomeNotFound';
import SlihomeUnderConstruction from '../SHError/SlihomeUnderConstruction';
import SliNewHomePage from '../SHNewHomePage/SHNewHomePage';
import SHPrivatePolicy from '../SHPrivatePolicy/SHPrivatePolicy';
import SHOneRoom from '../SHOneRoom/SHOneRoom';

export const PATHS = {
    "ROOT":"/",
    "ALL": "*",
    "HOME_PAGE": "/trang-chu",
    "SIGN_IN": "/dang-nhap",
    "SIGN_UP": "/dang-ky",
    "FORGOT_PASSWORD": "/quen-mat-khau",
    "MAIN_APP": "/app",
    "USER_PROFILE": "/thong-tin-nguoi-dung",
    "TERM": "/term-of-service",
    "PRIVATE_POLICY": "/private-policy",
    "NOT_FOUND": "/404",
    "UNDER_CONSTRUCTION": "/coming-soon",
    "USER_GUIDE": "/cam-nang",
    "ONE_ROOM": "/room",
}

export const ROUTES = [
    {
        path: PATHS.HOME_PAGE,
        Component: SliNewHomePage,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.SIGN_IN,
        Component: SlihomeAuth,
        isPublic: true,
        withHeader: false
    },
    {
        path: PATHS.SIGN_UP,
        Component: SlihomeSignup,
        isPublic: true,
        withHeader: false
    },
    {
        path: PATHS.FORGOT_PASSWORD,
        Component: SlihomeForgotPassword,
        isPublic: true,
        withHeader: false
    },
    {
        path: PATHS.MAIN_APP,
        Component: SlihomeApp,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.USER_PROFILE,
        Component: SlihomeUserProfile,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.TERM,
        Component: SlihomeTermService,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.USER_GUIDE,
        Component: SlihomeUnderConstruction,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.NOT_FOUND,
        Component: SlihomeNotFound,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.UNDER_CONSTRUCTION,
        Component: SlihomeUnderConstruction,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.PRIVATE_POLICY,
        Component: SHPrivatePolicy,
        isPublic: true,
        withHeader: true
    },
    {
        path: PATHS.ONE_ROOM,
        Component: SHOneRoom,
        isPublic: true,
        withHeader: true
    }
]

export const LOCAL_STORAGE = {
    "AUTH_INFO": "authInfo",
    "LOCATION_TAGS": "locationTags",
    "GENERAL_TAGS": "generalTags",
    "LOCATION_TAGS_UPDATED_TIME": "locationTagsUpdateTime",
    "GENERAL_TAGS_UPDATED_TIME": "generalTagsUpdateTime"
}