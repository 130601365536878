import { Grid, Typography, Link, Avatar, Paper, Box, Tooltip, MuiThemeProvider, withStyles, Divider } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { PATHS } from '../../Const';
import SlihomeTheme from '../../Models/SlihomeMuiTheme';
import SlihomeTagsHolder from './SlihomeTagsHolder';

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const SlihomeReview = ({
    reviewContext
}) => {
    const [open, setOpen] = useState(false);
    const [previewList, setPreviewList] = useState(reviewContext.Pictures||[]);

    const handleOpen = (event) => {
        if(reviewContext.Pictures instanceof Array)
            setPreviewList([...reviewContext.Pictures].sort(function(x,y){ return x === event.target.src ? -1 : y === event.target.src ? 1 : 0; }))
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MuiThemeProvider theme={SlihomeTheme}>
            <Paper elevation={3} style={{ margin: "0.2rem", padding: "0.5rem", backgroundColor: "#FEFEFE" }}>
                <Grid container>
                    <Grid item xs={2}>
                        <Link href={`${PATHS.USER_PROFILE}?username=${reviewContext.PK.split('#')[2]}`}>
                            <Avatar alt={reviewContext.Name} src={reviewContext.Picture} style={{maxWidth: "100%", height: "auto"}} />
                        </Link>
                    </Grid>
                    <Grid item xs={10} container direction="column">
                        <Grid item container>
                            <Grid item md={8}>
                                <Typography color="primary">
                                    <Link href={`${PATHS.USER_PROFILE}?username=${reviewContext.PK.split('#')[2]}`}>
                                        {reviewContext.Name}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item md={4} style={{display: "flex"}}>
                                <Tooltip title={reviewContext.Rating}>
                                    <StyledRating
                                        size="small"
                                        name={reviewContext.SK}
                                        value={reviewContext.Rating}
                                        readOnly
                                        precision={0.5}
                                        icon={<FavoriteIcon fontSize="inherit" />}
                                        style={{margin: "auto"}}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid item>
                            <Typography>
                                {reviewContext.Comment}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SlihomeTagsHolder locationTags={reviewContext.Loc} generalTags={reviewContext.Gen}/>
                        </Grid>
                        {
                        reviewContext.Pictures instanceof Array ?
                            <Grid item style={{height: "5rem"}}>
                                <Scrollbars style={{width:"100%", height: "100%"}}>
                                    <Box style={{height: "95%", display: "inline-flex"}}>
                                        {reviewContext.Pictures.map((tile) => (
                                            <img key={tile} src={tile} alt="Ảnh review" style={{display: "inline-block", height: "100%", borderRadius: "5px"}} onClick={handleOpen}/>
                                        ))}
                                    </Box>
                                </Scrollbars>
                            </Grid>
                            :
                            null
                        }
                        <Grid item>
                            <Box fontStyle="italic" style={{ float: "right" }}>
                                    {getTimeAgo(reviewContext?.AdvertisementValidFrom || reviewContext?.SK)}
                                </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {reviewContext.Pictures instanceof Array ?
                    <AutoRotatingCarousel
                        open={open}
                        autoplay={false}
                        onClose={handleClose}
                        onStart={handleOpen}
                        style={{ position: 'absolute' }}
                    >
                        {
                            previewList.map((tile) => (
                                <Paper key={tile} style={{width: "100%", height: "100%", display: "flex", backgroundColor: "rgb(150,150,150)"}}>
                                    <img src={tile} alt="Ảnh review" style={{margin: "auto", maxHeight: "100%", maxWidth: "100%"}} />
                                </Paper>
                            ))
                        }
                    </AutoRotatingCarousel>
                    : null
                }
            </Paper>
        </MuiThemeProvider>
    )
}

export default SlihomeReview

function getTimeAgo(SK) {
    try {
        return timeSince(new Date(getReviewTime(SK)));
    }
    catch (err) {
        return "";
    }
}

function getReviewTime(SK) {
    return new Date(parseInt(SK.split("#").pop()));
}

function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " năm trước";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " tháng trước";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " ngày trước";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " giờ trước";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " phút trước";
    }
    return "vừa xong";
}