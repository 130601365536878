import React, {
    useState,
    useEffect
} from "react";

import {
    useLocation,
    useHistory
} from "react-router-dom";

import {
    Grid,
    makeStyles,
    Link,
    Avatar,
    Divider,
    withStyles,
    Typography,
    TextField,
    Checkbox,
    CircularProgress
} from "@material-ui/core";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Scrollbars from 'react-custom-scrollbars';
import ImageUploader from 'react-images-upload';

import FavoriteIcon from '@material-ui/icons/Favorite';
import {
    Rating,
    Autocomplete
} from '@material-ui/lab/';

import { store } from 'react-notifications-component';

import {
    OverlayTrigger,
    Tooltip,
    Button,
} from "react-bootstrap"

import { Helmet } from "react-helmet";

import {
    useMediaQuery
} from "react-responsive";

import ImageGallery from 'react-image-gallery';

import QRCode from "qrcode.react";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PATHS, LOCAL_STORAGE } from "./../Const/index";

import { IMAGE_PURPOSE, SlihomeAPIClient } from '../Models/SlihomeAPI';

import { LS } from "../Models/SlihomeLocalStorage";

import SHOneRoomReview from "./Components/SHOneRoomReview";

import "./SHOneRoom.css";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: "10em"
    },
    divider: {
        width: "100%",
        marginTop: "2em"
    },
    carousel: {
        maxWidth: "100%"
    }
}));

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SHOneRoom = () => {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const paramGetter = new URLSearchParams(location.search);

    const isMuiSm = useMediaQuery({
        query: '(min-width: 600px)'
    })

    const isMuiMd = useMediaQuery({
        query: '(min-width: 960px)'
    })

    const [pageTitle, setPageTitle] = useState("Sli Home")
    const [pageDescription, setPageDescription] = useState("Một căn phòng trên nền tảng Sli Home");
    const [showLessorInfo, setShowLessorInfo] = useState(false);
    const [lessorInfo, setLessorInfo] = useState({});
    const [roomData, setRoomData] = useState({});
    const [roomImages, setRoomImages] = useState([]);
    const [isGettingLessorInfo, setIsGettingLessorInfo] = useState(false);
    const [hover, setHover] = useState(-1);
    const [rating, setRating] = useState(2.5);
    const [userInfo, setUserInfo] = useState({});
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);

    const [locationTags, setLocationTags] = useState([]);
    const [generalTags, setGeneralTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [tags, setTags] = useState([]);
    const [images, setImages] = useState([]);

    const [loading, setLoading] = useState(false);

    const [reviewList, setReviewList] = useState([]);

    const [showShareInfo, setShowShareInfo] = useState(false)

    const getLessorInfo = async () => {
        setShowLessorInfo(true);
        if (localStorage.getItem(LOCAL_STORAGE.AUTH_INFO)) {
            setIsGettingLessorInfo(true);
            let lessorInfoResult = await SlihomeAPIClient.getPublicUserDetail({
                targetUsername: roomData["PK"].split("#")[2]
            });
            if (Object.keys(lessorInfoResult).length) {
                let result = {};
                result["phone"] = lessorInfoResult["data"]["Username"];
                lessorInfoResult["data"]["UserAttributes"].forEach(attr => {
                    if (attr["Name"] === "name") {
                        result["name"] = attr["Value"];
                    }
                    else if (attr["Name"] === "picture") {
                        result["picture"] = attr["Value"];
                    }
                    else if (attr["Name"] === "email") {
                        result["email"] = attr["Value"];
                    }
                })
                setLessorInfo(result);
                setIsGettingLessorInfo(false);
                await SlihomeAPIClient.increaseGetLessorInfoTimes({
                    roomID: roomData["SK"]
                });
            }
            else {
                setIsGettingLessorInfo(false);
            }
        }
    }


    function onComment(event) {
        setCommentError(false);
        setComment(event.target.value);
    }

    function tagOnChange(event, value) {
        // console.log(document.getElementById("review-filter-tags"));
        setTags(value);
    }

    function onDrop(picture, data) {
        let picList = [];
        picture.forEach((file, index) => {
            picList.push({
                fileName: file.name,
                content: data[index].split(',')[data[index].split(',').length - 1]
            })
        });
        setImages(picList);
    }

    function generateTagFilter(tagList = []) {
        let general = [];
        let location = [];
        tagList.forEach(ele => {
            let tagType = ele.id.split("@")[0];
            let tagIndex = parseInt(ele.id.split("@")[1]);
            switch (tagType) {
                case "general":
                    general.push(tagIndex);
                    break;
                case "location":
                    location.push(tagIndex);
                    break;
                default:
            }
        })
        let generalTag = Array.apply(null, Array(Object.keys(generalTags).length)).map((ele, index) => general.indexOf(index) < 0 ? 0 : 1).join(',');
        let locationTag = Array.apply(null, Array(Object.keys(locationTags).length)).map((ele, index) => location.indexOf(index) < 0 ? 0 : 1).join(',');
        return {
            generalTag: generalTag,
            locationTag: locationTag
        }
    }

    const postReviewHandler = async () => {
        if (comment.trim().length < 10) {
            setCommentError(true);
        }
        else {
            try {
                setLoading(true);
                let imgRes = await Promise.all(images.map(ele =>
                    SlihomeAPIClient.uploadImage({
                        purpose: IMAGE_PURPOSE.REVIEW,
                        fileName: ele.fileName,
                        content: ele.content
                    })
                ))
                SlihomeAPIClient.postReview({
                    roomID: roomData["SK"],
                    rating: rating,
                    comment: comment.trim(),
                    ...generateTagFilter(tags),
                    pictures: imgRes.map(ele => ele.data.Location)
                }).then(response => {
                    if (response.data.Success) {
                        store.addNotification({
                            title: "Thành công",
                            message:
                                `Đã đăng thành công đánh giá`,
                            type: "success",
                            insert: "top",
                            container: "bottom-left",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        setRating(2.5);
                        setComment("");
                        setTags([]);
                        setImages([]);
                        getReviewsForRoom(roomData["SK"]);
                    }
                    else {
                        store.addNotification({
                            title: "Lỗi",
                            message:
                                `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                            type: "danger",
                            insert: "top",
                            container: "bottom-left",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }).catch(error => {
                    console.error(error)
                    store.addNotification({
                        title: "Lỗi",
                        message:
                            `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                        type: "danger",
                        insert: "top",
                        container: "bottom-left",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }).finally(() => {
                    setLoading(false);
                })
            } catch (err) {
                setLoading(false);
                store.addNotification({
                    title: "Lỗi tải ảnh lên",
                    message:
                        `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                    type: "danger",
                    insert: "top",
                    container: "bottom-left",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                console.error(err)
            }
        }
    }

    const shareBtnClickHandler = () => {
        setShowShareInfo(!showShareInfo);
    }

    const shareLinkCopySuccessHandler = () => {
        store.addNotification({
            title: "Thành công",
            message:
                `Đã sao chép đường dẫn vào bộ đệm`,
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000,
                onScreen: true
            }
        });
    }

    const getQrCodeFilename = (roomID) => {
        let hostname = window.location.hostname;
        if (hostname === "slihome.com") {
            hostname = "prod";
        }
        else if (hostname === "staging.slihome.com") {
            hostname = "staging";
        }
        else {
            hostname = "dev";
        }
        let result = `SliHome-${hostname}-${roomID}`;
        return result;
    }

    const shareQrClickHandler = () => {
        const canvas = document.getElementById("share-qr");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${getQrCodeFilename(roomData["SK"])}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const getReviewsForRoom = async (roomID) => {
        let reviewResult = await SlihomeAPIClient.getReviewList({
            roomID: roomID
        })
        if (reviewResult["data"]["Success"]) {
            setReviewList(reviewResult["data"]["Result"]);
        }
    }

    const initCheck = async () => {
        let roomID = paramGetter.get("roomID");
        if (roomID === null) {
            history.push("/404");
        }
        else {
            let roomDataResult = await SlihomeAPIClient.getOneRoom({ roomID: roomID });
            roomDataResult = roomDataResult.data.Result.Items[0];
            if (roomDataResult === undefined) {
                history.push("/404");
            }
            else {
                setRoomData(roomDataResult);
                setPageTitle(roomDataResult["RoomName"]);
                setPageDescription(roomDataResult["Description"]);
                let roomImagesList = []
                roomDataResult["Pictures"].forEach((picture, idx, arr) => {
                    roomImagesList.push({
                        original: picture,
                        thumbnail: picture,
                    })
                    if (idx === arr.length - 1) {
                        setRoomImages(roomImagesList);
                    }
                })
            }
        }
        if (localStorage.getItem(LOCAL_STORAGE.AUTH_INFO)) {
            let userInfoResult = await SlihomeAPIClient.getOwnUserDetail({
                accessToken: localStorage.getItem(LOCAL_STORAGE.AUTH_INFO["accessToken"])
            });
            let userAttributes = {};
            userInfoResult["data"]["UserAttributes"].forEach((attr, idx, arr) => {
                userAttributes[attr["Name"]] = attr["Value"];
                if (idx === arr.length - 1) {
                    userAttributes["username"] = userInfoResult["data"]["Username"];
                    setUserInfo(userAttributes);
                }
            })
        }
        await getReviewsForRoom(roomID);
    }

    useEffect(() => {
        initCheck();
    }, [])

    useEffect(() => {
        Promise.all([LS.getLocationTags(), LS.getGeneralTags()]).then(response => {
            setLocationTags(response[0]);
            setGeneralTags(response[1]);
        })
    }, [])

    useEffect(() => {
        let suggestions = [];
        Object.keys(generalTags).forEach(ele => {
            suggestions.push({
                id: `general@${ele}`,
                name: generalTags[ele]
            });
        })
        Object.keys(locationTags).forEach(ele => {
            suggestions.push({
                id: `location@${ele}`,
                name: `Gần ${locationTags[ele]}`
            });
        });
        setSuggestions(suggestions)
    }, [locationTags, generalTags])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
            </Helmet>
            <Grid className={classes.container} style={{ width: isMuiMd ? "80%" : "90%", marginLeft: isMuiMd ? "10%" : "5%" }} container direction="column" spacing={5} alignItems="center">
                {roomData === {}
                    ? "Loading..."
                    : (
                        <>
                            <Grid className={classes.carousel} style={{ width: isMuiMd ? "70%" : isMuiSm ? "85%" : "100%" }} item container justify="center">
                                {
                                    roomImages.length === 0
                                        ?
                                        <div style={{ width: "100%", height: "100%" }}>
                                            <img src="/icon/search-idle.svg" alt="Không có hình ảnh" />
                                            Không có hình ảnh
                                        </div>
                                        :
                                        <ImageGallery items={roomImages} showIndex thumbnailPosition="right" renderLeftNav={(onClick, disabled) => {
                                            return (
                                                <button
                                                    className='image-gallery-custom-left-nav'
                                                    disabled={disabled}
                                                    onClick={onClick} >
                                                        <ChevronLeftIcon />
                                                    </button>
                                            )
                                        }} renderRightNav={(onClick, disabled) => {
                                            return (
                                                <button
                                                    className='image-gallery-custom-right-nav'
                                                    disabled={disabled}
                                                    onClick={onClick} >
                                                        <ChevronRightIcon />
                                                    </button>
                                            )
                                        }} />
                                }
                            </Grid>
                            <Grid container className="post-info" spacing={5}>
                                <Grid item container className="room-info" xs={12} sm={8} lg={9} spacing={1} alignContent="flex-start">
                                    <Grid item className="room-info-title">
                                        {roomData["RoomName"]}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span fontStyle="italic">({getTimeAgo(roomData["AdvertisementValidFrom"])})</span>
                                    </Grid>
                                    <Grid item container className="room-info-important" alignItems="center">
                                        <Grid item className="gradient-text room-info-cost" xs={12} md>
                                            {numberWithCommas(roomData["Cost"])} VNĐ
                                        </Grid>
                                        <Grid item container className="gradient-text room-info-gender" justify={isMuiMd ? "flex-end" : "flex-start"} xs md>
                                            {
                                                roomData["MaxSlots"] > 0 ?
                                                    <div className="gradient-text card-currentSlot">{roomData["MaxSlots"] - roomData["CurrentSlot"]}</div>
                                                    : ""
                                            }
                                            {checkGenderAllowance(roomData["GenderAllowance"])}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="room-info-subtitle">
                                        Thông tin cơ bản
                                    </Grid>
                                    <Grid item container className="room-info-subinfo">
                                        <Grid item className="room-info-subinfo-description" xs={12}>
                                            <img src="/icon/paper-color.svg" className="view-icon" alt="paper-icon" />
                                            <span className="card-text">Mô tả: {roomData["Description"]}</span>
                                        </Grid>
                                        <Grid item className="room-info-subinfo-address" xs={12}>
                                            <img src="/icon/path-931.svg" className="view-icon" alt="paper-icon" />
                                            <span className="card-text">Địa chỉ: {roomData["Address"]}</span>
                                        </Grid>
                                        <Grid item className="room-info-subinfo-area" xs={12} sm={6} lg>
                                            <img src="/icon/path-1141.svg" className="view-icon" alt="paper-icon" />
                                            <span className="card-text">Diện tích: {roomData["Area"]} m<sup>2</sup></span>
                                        </Grid>
                                        {
                                            roomData["Floor"] > 0
                                                ? (
                                                    <Grid item className="room-info-subinfo-floor" xs={12} sm={6} lg>
                                                        <img src="/icon/home2tang.svg" className="view-icon" alt="paper-icon" />
                                                        <span className="card-text">Tầng: {roomData["Floor"]}</span>
                                                    </Grid>
                                                )
                                                : ""
                                        }
                                        {
                                            roomData["Note"] !== ""
                                                ? (
                                                    <Grid item className="room-info-subinfo-note" xs={12} sm={6} lg>
                                                        <img src="/icon/note.svg" className="view-icon" alt="paper-icon" />
                                                        <span className="card-text">Thông tin thêm: {roomData["Note"]}</span>
                                                    </Grid>
                                                )
                                                : ""
                                        }
                                    </Grid>
                                    <Grid item xs={12} className="room-info-subtitle">
                                        Thông tin thanh toán và các chi phí phát sinh
                                    </Grid>
                                    {
                                        roomData["ElectricCost"] >= 0
                                            ? (
                                                <Grid item className="room-info-subinfo-electriccost" xs={4}>
                                                    <img src="/icon/pay-color.svg" className="view-icon" alt="paper-icon" />
                                                    <span className="card-text">Điện: {numberWithCommas(roomData["ElectricCost"])} VNĐ / số điện </span>
                                                </Grid>
                                            )
                                            : ""
                                    }
                                    {
                                        roomData["WaterCost"] >= 0
                                            ? (
                                                <Grid item className="room-info-subinfo-watercost" xs={4}>
                                                    <img src="/icon/pay-color.svg" className="view-icon" alt="paper-icon" />
                                                    <span className="card-text">Nước: {numberWithCommas(roomData["WaterCost"])} VNĐ / số nước </span>
                                                </Grid>
                                            )
                                            : ""
                                    }
                                    {
                                        roomData["InternetCost"] >= 0
                                            ? (
                                                <Grid item className="room-info-subinfo-internetcost" xs={4}>
                                                    <img src="/icon/pay-color.svg" className="view-icon" alt="paper-icon" />
                                                    <span className="card-text">Điện: {numberWithCommas(roomData["InternetCost"])} VNĐ / tháng </span>
                                                </Grid>
                                            )
                                            : ""
                                    }
                                    <Grid item className="room-info-subinfo-paymentdate" xs={12}>
                                        <img src="/icon/month-color.svg" className="view-icon" alt="calendar-icon" />
                                        <span className="card-text">Thanh toán vào {roomData["PaymentDate"] <= 10 ? `mùng ${roomData["PaymentDate"]}` : roomData["PaymentDate"]} hàng tháng</span>
                                    </Grid>
                                </Grid>
                                <Grid item container className="lessor-info" xs sm lg direction="column" spacing={2}>
                                    {showLessorInfo
                                        ? localStorage.getItem(LOCAL_STORAGE.AUTH_INFO)
                                            ? isGettingLessorInfo
                                                ? "Đang lấy thông tin chủ nhà"
                                                : Object.keys(lessorInfo).length
                                                    ? (
                                                        <>
                                                            <Grid item container className="lessor-info-avatar" justify="center">
                                                                <Link href={`${PATHS.USER_PROFILE}?username=${lessorInfo["phone"].replace("+", "%2B")}`}>
                                                                    <Avatar alt={`lessor-avatar-${lessorInfo["name"]}`} src={lessorInfo["picture"]} style={{ width: "100px", height: "auto" }} />
                                                                </Link>
                                                            </Grid>
                                                            <Grid item className="lessor-info-name">
                                                                <img src="/icon/user.svg" className="view-icon" alt="user-icon" />
                                                                Tên: {lessorInfo["name"]}
                                                            </Grid>
                                                            <Grid item className="lessor-info-phone">
                                                                <img src="/icon/telephone.svg" className="view-icon" alt="phone-icon" />
                                                                Số điện thoại: {lessorInfo["phone"]}
                                                            </Grid>
                                                            {
                                                                lessorInfo["email"]
                                                                    ? (
                                                                        <Grid item className="lessor-info-email">
                                                                            <img src="/icon/email.svg" className="view-icon" alt="email-icon" />
                                                                            Email: {lessorInfo["email"]}
                                                                        </Grid>
                                                                    )
                                                                    : ""
                                                            }
                                                        </>
                                                    )
                                                    : "Không tìm thấy thông tin chủ nhà"
                                            : (<span className="card-text">Hãy đăng nhập/đăng ký trên trình duyệt này để lấy thông tin chủ nhà<br />Hoặc vào <Link href="/">trang chủ</Link> để tải app</span>)
                                        : <Button variant="outline-danger" onClick={getLessorInfo} size="sm" disabled={isGettingLessorInfo}>Lấy thông tin liên hệ</Button>
                                    }
                                    <Button variant="outline-danger" size="sm" className="share-btn" onClick={shareBtnClickHandler}>Chia sẻ phòng</Button>
                                    {showShareInfo
                                        ? (
                                            <Grid item xs className="share-container" container direction="column" alignItems="center" spacing={6}>
                                                <Grid item className="share-link" style={{ wordBreak: "break-word" }}>
                                                    <Typography variant="label" style={{ marginRight: "1em", fontWeight: "600" }}>
                                                        Đường dẫn: <br />
                                                    </Typography>
                                                    <CopyToClipboard text={window.location.href} onCopy={shareLinkCopySuccessHandler}>
                                                        <Link style={{ cursor: "pointer" }}>
                                                            {window.location.href}
                                                        </Link>
                                                    </CopyToClipboard>
                                                </Grid>
                                                <Grid item className="share-qr" style={{ width: "100%" }}>
                                                    <Typography variant="label" style={{ marginRight: "1em", fontWeight: "600" }}>
                                                        Mã QR: Click để tải <br />
                                                    </Typography>
                                                    <Button variant="none" onClick={shareQrClickHandler}>
                                                    <QRCode id="share-qr" value={window.location.href} size="150" level="M" imageSettings={{ src: "/images/img_embeded_qr.svg", width: "50", height: "50" }} />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                        : ""
                                    }
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid item xs={12} className="room-info-subtitle">
                                Bình luận, đánh giá
                            </Grid>
                            <Grid item container className="post-review" xs spacing={5} justify="center" style={{ padding: "0" }}>
                                {localStorage.getItem(LOCAL_STORAGE.AUTH_INFO)
                                    ? (
                                        <>
                                            <Grid item container className="review-avatar" xs={12} sm={3} lg={2} xl={1} justify="center" alignItems="center">
                                                <img src={userInfo["picture"]} alt="user-avatar" width="100px" />
                                            </Grid>
                                            <Grid item container className="review-content" xs justify={isMuiSm ? "flex-start" : "center"} direction="column" spacing={3} style={{ width: "100%", padding: "0" }}>
                                                <Grid item xs container alignItems="center" direction="column">
                                                    <Grid item xs>Đánh giá:</Grid>
                                                    <Grid item xs>
                                                        <StyledRating
                                                            color="primary"
                                                            size="large"
                                                            name="rating-review"
                                                            defaultValue={2.5}
                                                            value={rating}
                                                            precision={0.5}
                                                            icon={<FavoriteIcon fontSize="inherit" />}
                                                            onChange={(ev, newVal) => {
                                                                setRating(newVal)
                                                            }}
                                                            onChangeActive={(event, newHover) => {
                                                                setHover(newHover);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        {rating !== null && <Typography style={{ textAlign: "center", fontWeight: 600, color: "#373737" }}>
                                                            {labels[hover !== -1 ? hover : rating]}
                                                        </Typography>}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs container direction="column" >
                                                    <Grid item xs>
                                                        <TextField
                                                            required
                                                            error={commentError}
                                                            value={comment}
                                                            onChange={onComment}
                                                            style={{ width: "100%" }}
                                                            label="Bình luận"
                                                            helperText="Tối thiểu 10 ký tự không tính khoảng trắng"
                                                            multiline
                                                            rows={4}
                                                            placeholder="Nhập vào bình luận (Tối đa 500 ký tự)"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs container direction="column" >
                                                    <Grid item xs>
                                                        <Autocomplete
                                                            size="small"
                                                            multiple
                                                            limitTags={2}
                                                            id="review-filter-tags"
                                                            options={suggestions}
                                                            onChange={tagOnChange}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(option) => option.name}
                                                            renderOption={(option, { selected }) => (
                                                                <React.Fragment>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option.name}
                                                                </React.Fragment>
                                                            )}
                                                            fullWidth
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="Tìm theo tag" placeholder="Thêm tag" />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: "100%", flex: 1, height: "auto" }}>
                                                    <Scrollbars autoHeight>
                                                        <ImageUploader
                                                            buttonText='Chọn hình ảnh'
                                                            onChange={onDrop}
                                                            imgExtension={['.jpg', '.gif', '.png']}
                                                            buttonStyles={{ paddingTop: '200px', fontWeight: '600', opacity: 0, marginTop: '-20%', position: 'absolute', paddingBottom: '100px' }}
                                                            maxFileSize={5242880}
                                                            withPreview
                                                        />
                                                    </Scrollbars>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} justify="center">
                                                <Button
                                                    type="button"
                                                    id="submitButton"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className="submit-btn"
                                                    style={{
                                                        "borderRadius": "10rem",
                                                        "height": "3rem",
                                                        "fontWeight": "bolder",
                                                        "fontFamily": "Open Sans",
                                                        "fontSize": "16px"
                                                    }}
                                                    onClick={postReviewHandler}
                                                    disabled={loading}
                                                >
                                                    {loading ? <CircularProgress style={{ color: "#FFFFFF" }} /> : "GỬI ĐÁNH GIÁ"}
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                    : "Hãy đăng nhập/đăng ký Sli Home để tham gia bình luận"
                                }
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid item xs={12} className="room-info-subtitle">
                                Danh sách bình luận
                            </Grid>
                            <Grid item xs={12} container direction="column" spacing={5}>
                                {reviewList.length > 0
                                    ? reviewList.map(review => (
                                        <div key={review["SK"]}>
                                            <SHOneRoomReview reviewInfo={review} />
                                            <Divider className={classes.divider} />
                                        </div>
                                    ))
                                    : "Hiện không có bình luận nào"
                                }
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </div>
    )
}

export default SHOneRoom

function numberWithCommas(x) {
    if (x && x.toString instanceof Function)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


function checkGenderAllowance(GenderAllowance) {
    if (GenderAllowance === "Male") {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nam</Tooltip>}
            >
                <div className="allowanceProp genderAllowance">
                    <img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
                    <img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
                    <img src="/icon/female-symbol.svg" className="card-icon" alt="female-deactive" />
                </div>
            </OverlayTrigger>
        )
    }
    else if (GenderAllowance === "Female") {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nữ</Tooltip>}
            >
                <div className="allowanceProp genderAllowance">
                    <img src="/icon/male-symbol.svg" className="card-icon" alt="male-deactive" />
                    <img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
                    <img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
                </div>
            </OverlayTrigger>
        )
    }
    else {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Cả nam và nữ</Tooltip>}
            >
                <div className="allowanceProp genderAllowance">
                    <img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
                    <img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
                    <img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
                </div>
            </OverlayTrigger>
        )
    }
}

function getTimeAgo(SK) {
    try {
        return timeSince(new Date(getReviewTime(SK)));
    }
    catch (err) {
        return "";
    }
}


function getReviewTime(SK) {
    return new Date(parseInt(SK));
}

function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " năm trước";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " tháng trước";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " ngày trước";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " giờ trước";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " phút trước";
    }
    return "vừa xong";
}
