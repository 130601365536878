import React from "react";
import "./SlihomeView.css";
import { Container, Row, Col } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import SlihomeReviewList from "./SlihomeReviewList";
import SlihomeTextInfo from "./SlihomeTextInfo";

class SlihomeView extends React.Component {
	constructor(props) {
		super(props);
		this.images = [];
		if (this.props.data.Pictures) {
			this.props.data.Pictures.forEach(ele => {
				this.images.push({
					original: ele,
					thumbnail: ele,
				})
			})
		}
		this.state = {
			loadLessorInfo: false,
			lessorInfo: undefined
		}
	}

	render() {
		if (this.props.data) {
			return (
				<div id="view-container">
					<button class="btn close-view">✖</button>
					{/* <Row style={{position: "absolute", height: "100%", width: "100%"}}>
						<Row>
							{
								this.images.length === 0
									?
									<div style={{ width: "100%", height: "100%" }}>
										<img src="/icon/search-idle.svg" alt="Không có hình ảnh" />
										Không có hình ảnh
									</div>
									:
									<ImageGallery items={this.images} showIndex />
							}
						</Row>
						<Row className='house-information'>
							<SlihomeTextInfo data={this.props.data} />
						</Row>
						<Col lg={4}>
							<Row style={{ height: "100%" }}>
								<Col style={{ height: "100%" }}>
									<SlihomeReviewList roomInfo={this.props.data} />
								</Col>
							</Row>
						</Col>
					</Row> */}
					<div>
						{
							this.images.length === 0
								?
								<div style={{ width: "100%", height: "100%" }}>
									<img src="/icon/search-idle.svg" alt="Không có hình ảnh" />
									Không có hình ảnh
								</div>
								:
								<ImageGallery 
									items={this.images} 
									showIndex 
									thumbnailPosition={'right'}
								/>
						}
					</div>
					<div className='house-information'>
						<SlihomeTextInfo data={this.props.data} />
					</div>
					<div style={{ height: "100%" }}>
						<Col style={{ height: "100%" }}>
							<SlihomeReviewList roomInfo={this.props.data} />
						</Col>
					</div>
				</div>
			)
		}
		else {
			return (
				<Container fluid id="view-container">
					<img src="/icon/construction.svg" style={{ height: "300px" }} alt="under construction"></img>
					<span>Không có thông tin</span>
				</Container>
			)
		}
	}
}

export default SlihomeView;
