import { LOCAL_STORAGE } from "../Const"
import { SlihomeAPIClient } from "../Models/SlihomeAPI";

export const LS = {
    getAuth: () => {
        try{
            return JSON.parse(localStorage.getItem(LOCAL_STORAGE.AUTH_INFO)) || {}
        } catch(err){
            window.dispatchEvent(new Event("invalidAuthInfo"));
            return {};
        }
    },
    getLocationTags: async () => {
        return new Promise((resolve) =>{
            try{
                if(new Date().getTime() - parseInt(localStorage.getItem(LOCAL_STORAGE.LOCATION_TAGS_UPDATED_TIME)) > 24*60*60*1000) throw Error("Old location tag list")
                if(!localStorage.getItem(LOCAL_STORAGE.LOCATION_TAGS)) throw Error("Missing location tag list")
                resolve(JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOCATION_TAGS)))
            } catch (err) {
                SlihomeAPIClient.getLocationTags().then( (response)=>{
                    let result = response.data;
                    if (result.Success) {
                        let locationTags = result.Result;
                        localStorage.setItem(LOCAL_STORAGE.LOCATION_TAGS, JSON.stringify(locationTags))
                        localStorage.setItem(LOCAL_STORAGE.LOCATION_TAGS_UPDATED_TIME, new Date().getTime())
                        resolve(locationTags)
                    }
                    else {
                        resolve([])
                    }
                }).catch(err=>{
                    console.error(err)
                    resolve([])
                })
            }
        })
    },
    setLocationTags: (locationTags) => {
        if(locationTags instanceof []){
            localStorage.setItem(LOCAL_STORAGE.LOCATION_TAGS, JSON.stringify(locationTags))
            localStorage.setItem(LOCAL_STORAGE.LOCATION_TAGS_UPDATED_TIME, new Date().getTime())
        }
        else {
            localStorage.removeItem(LOCAL_STORAGE.LOCATION_TAGS)
        }
    },
    getGeneralTags: async () => {
        return new Promise((resolve) =>{
            try{
                if(new Date().getTime() - parseInt(localStorage.getItem(LOCAL_STORAGE.GENERAL_TAGS_UPDATED_TIME)) > 24*60*60*1000) throw Error("Old general tag list")
                if(!localStorage.getItem(LOCAL_STORAGE.GENERAL_TAGS)) throw Error("Missing general tag list")
                resolve(JSON.parse(localStorage.getItem(LOCAL_STORAGE.GENERAL_TAGS)))
            } catch (err) {
                SlihomeAPIClient.getGeneralTags().then(response=>{
                    let result = response.data;
                    if (result.Success) {
                        let generalTags = result.Result;
                        localStorage.setItem(LOCAL_STORAGE.GENERAL_TAGS, JSON.stringify(generalTags))
                        localStorage.setItem(LOCAL_STORAGE.GENERAL_TAGS_UPDATED_TIME, new Date().getTime())
                        resolve(generalTags)
                    }
                    else {
                        resolve([])
                    }
                }).catch(err=>{
                    console.error(err)
                    resolve([])
                })
            }
        })
    },
    setGeneralTags:(generalTags) => {
        if(generalTags instanceof []){
            localStorage.setItem(LOCAL_STORAGE.GENERAL_TAGS, JSON.stringify(generalTags))
            localStorage.setItem(LOCAL_STORAGE.GENERAL_TAGS_UPDATED_TIME, new Date().getTime())
        }
        else {
            localStorage.removeItem(LOCAL_STORAGE.GENERAL_TAGS)
        }
    }
}