import React, { useEffect } from 'react';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconContext } from "react-icons";
import { FcGoogle } from 'react-icons/fc'
import { SiFacebook } from 'react-icons/si'
import "./SlihomeAuth.css";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SlihomeAPIClient, cognitoClientId, cognitoClientSecret, cognitoEndPoint } from '../Models/SlihomeAPI';
import axios from 'axios';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    fontFamily: "Open Sans",
  },
  typography:{
    fontWeight: "800",
  },
  typographyHeader:{
    color: '#484848',
    fontWeight: "bold",
    fontSize: "30px",
    fontFamily: "Open Sans",
  },
  typographyLink: {
    color: '#888888',
    fontWeight: "600",
  },
  typographyHint: {
    color: '#CECECE',
    fontWeight: "600",
    fontSize: "14px",
  },
  image: {
    backgroundImage: 'url(images/xAsset-1-1536x995.png.pagespeed.ic.U8-BcIT-1J.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderRadius: "0",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderColor: "#f02d4e !important"
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://slihome.com/">
        SLI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const getQueryParams = (url) => {
  let queryParams = {};
//create an anchor tag to use the property called search
  let anchor = document.createElement('a');
//assigning url to href of anchor tag
  anchor.href = url;
//search property returns the query string of url
  let queryStrings = anchor.search.substring(1);
  let params = queryStrings.split('&');

  for (var i = 0; i < params.length; i++) {
      var pair = params[i].split('=');
      queryParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  return queryParams;
};

function standardlizeAuthInfo (rawAuthInfo) {
  let authInfo = {};
  authInfo['AccessToken'] = rawAuthInfo['access_token'];
  authInfo['ExpiresIn'] = rawAuthInfo['expires_in'];
  authInfo['IdToken'] = rawAuthInfo['id_token'];
  authInfo['RefreshToken'] = rawAuthInfo['refresh_token'];
  authInfo['TokenType'] = rawAuthInfo['token_type'];
  authInfo['Username'] = jwt_decode(authInfo['IdToken'])['cognito:username'];
  return authInfo;
}

export default function SignInSide() {
  const classes = useStyles();
  let history = useHistory();
  useLocation();
  let loginCodeListener;

  const [loading, setLoading] = React.useState(false);

  const { authInfo } = localStorage

  useEffect(()=>{
    if(loginCodeListener){
      window.alert("removed");
      window.removeEventListener("loginCode", loginCodeListener);
    }
  })

  if(getQueryParams(window.location.href)["code"]){
    window.opener.dispatchEvent(new CustomEvent("loginCode",{
      detail: {
        code: getQueryParams(window.location.href)["code"]
      }
    }));
    window.close();
    return ("You can close this window now");
  }
  else{
    loginCodeListener = window.addEventListener("loginCode", handlerLogin3rdParty, false);

    function loginHandler(){
      let email = $('#email').val();
      let password = $('#password').val();
      if(validateEmail(email)){
        setLoading(true);
        clearResult();
        SlihomeAPIClient.signIn({
          username: email,
          password: password
        }).then((res)=>{
          let authInfo = res.data.AuthenticationResult;
          authInfo.Username = jwt_decode(authInfo.IdToken)["cognito:username"];
          localStorage.setItem("authInfo", JSON.stringify(authInfo));
          window.dispatchEvent(new Event("authInfoChanged"));
          history.push("/app");
        }).catch((err)=>{
          $('#resultHolderText').html("Sai thông tin đăng nhập");
          $('#resultHolder').fadeIn(300);
          setLoading(false);
        })
      }
      else {
        $('#resultHolderText').html("Email không đúng định dạng");
          $('#resultHolder').fadeIn(300);
      }
    }

    function handlerLogin3rdParty (event) {
      let code = event.detail.code;
      if(code){
        setLoading(true);
        axios.post(`${cognitoEndPoint}/oauth2/token?grant_type=authorization_code&code=${code}&redirect_uri=${window.location.href}&client_id=${cognitoClientId}&client_secret=${cognitoClientSecret}&scope=email+openid+profile+aws.cognito.signin.user.admin`
        ,{}
        ,{
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then((response)=>{
          let rawAuthInfo = response.data;
          localStorage.setItem('authInfo', JSON.stringify(standardlizeAuthInfo(rawAuthInfo)));
          window.dispatchEvent(new Event("authInfoChanged"));
          history.push("/app");
        })
        .catch((err)=>{
          console.error(err.response);
          setLoading(false);
        })
      }
    };

    function loginFacebook () {
      window.open(`${cognitoEndPoint}/oauth2/authorize?client_id=${cognitoClientId}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${window.location.href}&identity_provider=Facebook`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    function loginGoogle () {
      window.open(`${cognitoEndPoint}/oauth2/authorize?client_id=${cognitoClientId}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${window.location.href}&identity_provider=Google`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    function clearResult(event){
      if(event && event.target.name==="email" && !validateEmail(event.target.value)){
        $('#resultHolderText').html("Email không đúng định dạng");
        $('#resultHolder').fadeIn(300);
      }
      else {
        $('#resultHolder').fadeOut(300,()=>{
          $('#resultHolderText').empty();
        })
      }
    }

    if(authInfo) {
      return <Redirect to='/app' />
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Đăng nhập vào Slihome</title>
          <meta name="description" content="Đăng nhập để trải nghiệm nền tảng tìm trọ slihome ngay" />
        </Helmet>
        <Grid container component="main" className={classes.root} id="main">
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <a href="/">
                <img src="/images/cropped-imgpsh_fullsize_anim-192x192.png" alt="banner"/>
              </a>
              <Typography component="h1" className={classes.typographyHeader}>
                Đăng nhập
              </Typography>
              <form className={classes.form}>
                <TextField
                  onChange={clearResult}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  disabled={loading}
                  autoFocus
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <TextField 
                  onChange={clearResult}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Mật khẩu"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  disabled={loading}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <Grid container spacing={2} justify="space-between">
                  <Grid item>
                    <div id="resultHolder" className="resultHolder" style={{display: "none", transition: "none"}}>
                      <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                      <span id="resultHolderText"></span>
                    </div>
                  </Grid>
                  <Grid item>
                    <Link href="/quen-mat-khau" className={classes.typographyLink}>
                      Quên mật khẩu?
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justify="center">
                  <Grid item xs={10}>
                    <Button
                      type="button"
                      id="submitButton"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="signin-btn"
                      style={{
                        "borderRadius": "10rem",
                        "height": "3rem",
                        "fontWeight": "bolder",
                        "fontFamily": "Open Sans",
                        "fontSize": "16px"
                      }}
                      onClick={loginHandler}
                      disabled={loading}
                    >
                      {loading?<CircularProgress style={{color: "#FFFFFF"}}/>:"ĐĂNG NHẬP"}
                    </Button>
                  </Grid>
                  <Grid container item justify="space-between">
                    <Grid item>
                      <Typography className={classes.typographyHint}>
                        Chưa có tài khoản?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link href="/dang-ky" className="gradient-text">
                        ĐĂNG KÝ NGAY
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid item style={{"textAlign": "center", 'fontWeight': 'bold', 'color': '#888888'}}>
                    Hoặc
                  </Grid>
                  <Grid container item justify="center" spacing={3}>
                    <Grid item>
                      <IconButton className="loginViaButton" variant="outlined"color="secondary" onClick={loginFacebook}>
                        <IconContext.Provider value={{ color: "#3B5998"}}>
                          <SiFacebook size={40}/>
                        </IconContext.Provider>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton className="loginViaButton" variant="outlined" color="secondary" onClick={loginGoogle}>
                        <IconContext.Provider value={{}}>
                          <FcGoogle size={40}/>
                        </IconContext.Provider>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}