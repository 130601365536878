import React from "react";
import "./SlihomeCard.css";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Grid } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// class SlihomeCard extends React.Component {
// 	render() {
// 		return (
// 			<div className="card mb-3">
// 				<img className="card-img-top" src={this.props.data.Pictures[0]||"images/Room1.png"} alt="Card cap" />
// 				<div className="card-body">
// 					<div className="card-price-container">
// 						<div className="gradient-text">{numberWithCommas(this.props.data.Cost)} VND</div>
// 					</div>
// 					<div className="row">
// 						<div className="col-8">
// 							<h5 className="card-title">{this.props.data.Description}
// 							</h5>
// 						</div>
// 						<div className="col-4">
// 							<span className="card-text allowanceInfo">
// 								<div className="gradient-text allowanceProp">{this.props.data.CurrentSlot}</div>
// 								{checkGenderAllowance(this.props.data.GenderAllowance)}
// 							</span>
// 						</div>
// 					</div>
// 					<span className="card-text"><img src="/icon/path-931.svg" className="card-icon card-address-icon" alt="location mark" />{this.props.data.Address}</span>
// 				</div>
// 			</div>
// 		)
// 	}
// }



export default function SlihomeCard(props) {
	const useStyles = makeStyles({
		root: {
			maxWidth: 'auto',
			maxHeight: 'auto',
			height: "fit-content",
		},
		media: {
			width: '200px',
			height: '150px',
		},
		CardContent: {
			minHeigh: "fit-content"
		},
		cardTitle: {
			fontSize: "15px",
			fontWeight: 600,
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			"-webkit-line-clamp": "2",
			"-webkit-box-orient": "vertical",
			fontFamily: "Open Sans",
		},
		card2LinesContent: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			"-webkit-line-clamp": "2",
			"-webkit-box-orient": "vertical"
		}
	});

	const classes = useStyles();
	
	return (
		<Card className={classes.root} ref={props.innerRef} onClick={props.onClick} variant="outlined">
			<CardActionArea style={{height: "100%", display: 'flex'}}>
				<div>
					<CardMedia
						className={classes.media}
						image={props.data.Pictures?props.data.Pictures[0]?props.data.Pictures[0]:"images/Room1.png":"images/Room1.png"}
						title={props.data.AdvertiseTitle}
					/>
				</div>
				<div>
					<div className="card-price-container">
						<div className="gradient-text">{numberWithCommas(props.data.Cost)} VND</div>
					</div>
					<CardContent className={classes.CardContent}>
						<Grid container>
							<Grid item xs>
								<Typography gutterBottom className={classes.cardTitle}>
									{props.data.AdvertiseTitle}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<span className="card-text allowanceInfo">
									{
										props.data.MaxSlots>0?
											<div className="gradient-text allowanceProp">{props.data.MaxSlots - props.data.CurrentSlot}</div>
											:""
									}
									{checkGenderAllowance(props.data.GenderAllowance)}
								</span>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" color="textSecondary" component="p" className={classes.card2LinesContent} >
									<span className="card-text"><img src="/icon/path-931.svg" className="card-icon card-address-icon" alt="location mark" />{props.data.Address}</span>
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</div>
				
			</CardActionArea>
		</Card>
	);
}

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function checkGenderAllowance(GenderAllowance) {
	if (GenderAllowance === "Male") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nam</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol.svg" className="card-icon" alt="female-deactive" />
				</div>
			</OverlayTrigger>
		)
	}
	else if(GenderAllowance === "Female") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol.svg" className="card-icon" alt="male-deactive" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>	
		)
	}
	else {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Cả nam và nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>	
		)
	}
}