import React from "react";
import "./SlihomeHeader.css";
import { cognitoClientId, cognitoEndPoint, SlihomeAPIClient} from "../Models/SlihomeAPI"
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink, withRouter } from "react-router-dom";
import { AppBar, Divider, Hidden, IconButton, Menu, MenuItem, Toolbar } from "@material-ui/core";
import SlihomeTheme from "../Models/SlihomeMuiTheme";
import SlihomeHeaderHiddenNav from "./SlihomeHeaderHiddenNav";

class SlihomeHeader extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loggedIn: localStorage.getItem("authInfo")?true:false,
      userInfo: {
        "Username": "",
        "UserAttributes": {}
      },
      loading: true,
      anchorEl: null,
    }
  }

  componentWillUnmount() {
    window.removeEventListener("userInfoChanged", this.userInfoChangeHandler);
  }

  componentDidMount() {
    if(this.state.loggedIn){
      this.loadUserInfo();
    }
    this.userInfoChangeHandler = this.loadUserInfo.bind(this);
    window.addEventListener("userInfoChanged", this.userInfoChangeHandler);
  }

  loadUserInfo(){
    SlihomeAPIClient.getOwnUserDetail().then((res)=>{
      let data = res.data;
      data["UserAttributes"] = this.UserAttributesToJSON(data["UserAttributes"]);
      this.setState({
        userInfo: data
      });
    }).catch((err)=>{
      console.error(JSON.stringify(err));
    }).finally(()=>{
      this.setState({
        loading: false,
      })
    })
  }

  logOut(){
    localStorage.removeItem("authInfo");
    window.location.href = `${cognitoEndPoint}/logout?client_id=${cognitoClientId}&logout_uri=${window.location.origin}`;
  }

  UserAttributesToJSON(userAttributes=[]){
    let result = {};
    userAttributes.forEach(ele=>{
      result[ele.Name] = ele.Value;
    })
    return result;
  }

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = (event) => {
    this.setState({
      anchorEl: null
    });
  };

  render(){
    return (
      <div theme={SlihomeTheme}>
        <AppBar position="sticky" color="default">
          <Toolbar className="container">
            <Hidden mdUp>
              <SlihomeHeaderHiddenNav/>
              <Divider orientation="vertical" flexItem style={{marginRight: "1rem"}}/>
            </Hidden>
            <NavLink to="/" style={{flexGrow: 1,}}>
              <img src="images/Artboard – 89.svg" className="headerLogo" alt="Slihome Logo"></img>
            </NavLink>
            <Hidden smDown>
              <NavLink to="/">
                <h6 variant="h6" className={`navLink ${this.props.location.pathname==="/"?"nav-active":""}`}>
                  Giới thiệu
                </h6>
              </NavLink>
              <NavLink to="/app">
                <h6 variant="h6" className={`navLink ${this.props.location.pathname==="/app"?"nav-active":""}`}>
                  Tìm nhà
                </h6>
              </NavLink>
              {/* <NavLink to="/cam-nang">
                <h6 variant="h6" className={`navLink ${this.props.location.pathname==="/cam-nang"?"nav-active":""}`}>
                  Cẩm nang
                </h6>
              </NavLink> */}
              <NavLink to="/term-of-service">
                <h6 variant="h6" className={`navLink ${this.props.location.pathname==="/term-of-service"?"nav-active":""}`}>
                  Điều khoản
                </h6>
              </NavLink>
              <NavLink to="/private-policy">
                <h6 variant="h6" className={`navLink ${this.props.location.pathname==="/private-policy"?"nav-active":""}`}>
                  Chính sách bảo mật
                </h6>
              </NavLink>
              <a href="https://host.slihome.com" style={{marginRight: "2rem"}}>
                <h6 variant="h6" className={`navLink`}>
                  Dành cho chủ trọ
                </h6>
              </a>
            </Hidden>
            {/* <Divider orientation="vertical" flexItem style={{marginRight: "1rem"}}/> */}
            {
            this.state.loggedIn?
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu.bind(this)}
                  color="inherit"
                >
                  {
                    this.state.loading?
                      <CircularProgress size={25} color="primary"/>
                      :
                      <img src={this.state.userInfo.UserAttributes['picture']?this.state.userInfo.UserAttributes['picture']:"https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"} width="30" height="30" className="rounded-circle" alt="Ảnh đại diện"/>
                  }
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose.bind(this)}
                >
                  <MenuItem disabled> Chào {this.state.userInfo.UserAttributes['name']?this.state.userInfo.UserAttributes['name']:"người dùng"} </MenuItem>
                  <Divider />
                  <MenuItem component={NavLink} to="/thong-tin-nguoi-dung" onClick={this.handleClose.bind(this)}>Thông tin cá nhân</MenuItem>
                  <MenuItem component={NavLink} to="/thong-tin-nguoi-dung#doi-mat-khau" onClick={this.handleClose.bind(this)}>Đổi mật khẩu</MenuItem>
                  {/* <Divider /> */}
                  <MenuItem onClick={this.logOut.bind(this)}>Đăng xuất</MenuItem>
                </Menu>
              </div>
              :
              <NavLink to="/dang-nhap">
                <button
                type="button"
                color="primary"
                variant="contained"
                className="signin-btn" 
                >
                  Đăng nhập
                </button>
              </NavLink>
            }
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withRouter(SlihomeHeader);