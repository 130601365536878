import React from 'react';
import { Helmet } from 'react-helmet';
import '../SlihomeTerm/SHTermService.css';
import SHFooter from '../SHFooter/SHFooter';

const SHPrivatePolicy = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Điều khoản dịch vụ Slihome</title>
                <meta name="description" content="Chi tiết các điều khoản khi người dùng sử dụng dịch vụ tìm trọ Slihome" />
            </Helmet>
            <div id="page" className="site">
                <div className="container term-service-main">
                    <h1 className="text-content">CHÍNH SÁCH BẢO MẬT</h1>
                    <div className="purpose item-term-service">
                        <p className="title-term-service">1. Mục đích và phạm vi thu thập thông tin</p>
                        <p className="content-term-service">Việc thu thập thông tin cá nhân được thực hiện trên cơ sở khách hàng tự khai báo để đăng ký 
                            thành viên Sli Home tại website https://slihome.com hoặc trên ứng dụng di động của Sli Home, 
                            tùy từng thời điểm, thông tin thu thập sẽ bao gồm nhưng không giới hạn ở:</p>
                        <ul>
                            <li>Thông tin cá nhân như: họ tên, giới tính, ngày sinh, số CMND.</li>
                            <li>Thông tin liên lạc như: địa chỉ, số điện thoại di động, email/fax.</li>
                            <li>Các thông tin khác phục vụ cho chương trình khách hàng thân thiết (nếu có).</li>
                        </ul>
                        <p className="content-term-service">Mục đích thu thập thông tin khách hàng bao gồm:</p>
                        <ul>
                            <li>Cung cấp các dịch vụ, sản phẩm theo nhu cầu của khách hàng;</li>
                            <li>Liên hệ xác nhận khi khách hàng đăng ký sử dụng dịch vụ, xác lập giao dịch;</li>
                            <li>Thực hiện việc quản lý, gửi thông tin cập nhật, các chương trình khuyến mại, 
                                ưu đãi/tri ân tới khách hàng;</li>
                            <li>Bảo đảm quyền lợi của khách hàng khi phát hiện các hành động giả mạo, 
                                phá hoại tài khoản, lừa đảo khách hàng;</li>
                            <li>Liên lạc, hỗ trợ, giải quyết với khách hàng trong các trường hợp đặc biệt.</li>
                        </ul>
                        <p>Việc bạn truy cập, đăng ký, sử dụng Sli Home có nghĩa rằng bạn đồng ý và chấp nhận ràng buộc 
                            bởi các quy định trong chính sách bảo mật của chúng tôi.</p>
                        <p>Khách hàng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ 
                            dưới tên đăng ký, mật khẩu và hộp thư điện tử và/hoặc số điện thoại của mình. 
                            Sli Home không chịu trách nhiệm về các thất thoát dữ liệu, bí mật thông tin của 
                            khách hàng do khách hàng vô tình hoặc cố ý gây ra. Ngoài ra, Khách hàng có trách 
                            nhiệm thông báo kịp thời cho Sli Home về những hành vi sử dụng trái phép, lạm dụng, 
                            vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.</p>
                    </div>
                    <div className="use-information item-term-service">
                        <p className="title-term-service">2. Phạm vi sử dụng thông tin</p>
                        <p>Thông tin của khách hàng chỉ được sử dụng trong hoạt động quản lý 
                            và kinh doanh của website, cũng như cung cấp cho các bên liên kết 
                            với công ty. Chúng tôi cũng có thể dùng những thông tin chúng tôi 
                            tập hợp được để thông báo đến khách hàng những sản phẩm hoặc dịch 
                            vụ khác đang có trên Slihome.com hoặc những công ty liên kết với 
                            chúng tôi. Ngoài ra, chúng tôi còn có thể liên hệ với khách hàng
                             để biết đánh giá, ý kiến và quan điểm của khách hàng về những sản
                              phẩm và những dịch vụ hiện tại hoặc những sản phẩm và dịch vụ mới 
                              tiềm năng có thể sẽ được ra mắt. Thông tin cá nhân của khách hàng 
                              có thể bị chia sẻ với những công ty khác, nhưng chỉ trong trường hợp 
                              cần thiết để đáp ứng các yêu cầu của khách hàng, hoặc sử dụng cho những mục đích liên quan.</p>
                        <p>Chúng tôi còn sử dụng thông tin cá nhân của khách hàng để cung cấp dịch vụ phù hợp, đảm 
                            bảo cho việc thanh toán hoặc thanh toán tại trung tâm sẽ được bảo mật; phục vụ cho việc hỗ
                             trợ hệ thống chăm sóc khách hàng, quản lý khách hàng, chăm sóc và nhận ý kiến phản hồi từ phía 
                             khách hàng.</p>
                    </div>
                    <div className="time-information item-term-service">
                        <p className="title-term-service">3. Thời gian lưu trữ thông tin</p>
                        <p>Công ty có hệ thống máy chủ có khả năng lưu trữ thông tin khách hàng tối thiểu là 02 năm và tối đa là 10 năm. 
                            Trong quá trình hoạt động, công ty có thể nâng cao khả năng lưu trữ thông tin nếu cần thiết.</p>
                    </div>
                    <div className="edit-data item-term-service">
                        <p className="title-term-service">4. Cách thức chỉnh sửa dữ liệu cá nhân</p>
                        <p>Để chỉnh sửa dữ liệu cá nhân của mình trên hệ thống của Sli Home, khách hàng 
                            có thể tự đăng nhập và chỉnh sửa thông tin, dữ liệu cá nhân, ngoại trừ các 
                            thông tin về Họ tên, Giới tính, Ngày sinh và Chứng minh nhân dân.</p>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">5. Cam kết bảo mật thông tin cá nhân khách hàng</p>
                        <ul>
                            <li>Mọi thông tin cá nhân của khách hàng thu thập từ hệ thống Sli Home sẽ được 
                                lưu giữ an toàn; chỉ có khách hàng mới có thể truy cập vào tài khoản cá nhân 
                                của mình bằng tên đăng nhập và mật khẩu do khách hàng chọn.</li>
                            <li>Sli Home cam kết bảo mật thông tin, không chia sẻ, tiết lộ, chuyển giao 
                                thông tin cá nhân của khách hàng, thông tin giao dịch trực tuyến cho bất 
                                kỳ bên thứ ba nào khi chưa được sự đồng ý của khách hàng, trừ trường hợp 
                                phải thực hiện theo yêu cầu của các cơ quan Nhà nước có thẩm quyền, hoặc 
                                theo quy định của pháp luật hoặc việc cung cấp thông tin đó là cần thiết 
                                để Sli Home cung cấp dịch vụ/ tiện ích cho khách hàng</li>
                            <li>Sli Home, bằng nỗ lực tốt nhất của mình, sẽ áp dụng các giải pháp công 
                                nghệ để ngăn chặn các hành vi đánh cắp hoặc tiếp cận thông tin trái phép; 
                                sử dụng, thay đổi hoặc phá hủy thông tin trái phép. Tuy nhiên, Sli Home 
                                không thể cam kết sẽ ngăn chặn được tất cả các hành vi xâm phạm, sử dụng 
                                thông tin cá nhân trái phép nằm ngoài khả năng kiểm soát của Sli Home. 
                                Sli Home sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với bất 
                                kỳ khiếu nại, tranh chấp hoặc thiệt hại nào phát sinh từ hoặc liên quan 
                                đến việc truy cập, xâm nhập, sử dụng thông tin trái phép như vậy.</li>
                            <li>Trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát 
                                dữ liệu cá nhân, gây ảnh hưởng xấu đến khách hàng, Sli Home sẽ ngay lập 
                                tức thông báo cho khách hàng và trình vụ việc cho cơ quan chức năng điều tra xử lý.</li>
                            <li>Đối với các giao dịch trực tuyến được thực hiện thông qua Sli Home, Sli Home không lưu 
                                trữ thông tin thẻ thanh toán của khách hàng. Thông tin tài khoản, thẻ thanh toán của 
                                khách hàng sẽ được các đối tác cổng thanh toán của Itro bảo vệ theo tiêu chuẩn quốc tế.</li>
                            <li>Khách hàng có nghĩa vụ bảo mật tên đăng ký, mật khẩu và hộp thư điện tử của mình. Sli Home 
                                sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với các thiệt hại, tổn thất (nếu có)
                                 do khách hàng không tuân thủ quy định bảo mật này.</li>
                            <li>Khách hàng tuyệt đối không được có các hành vi sử dụng công cụ, chương trình để can thiệp 
                                trái phép vào hệ thống hay làm thay đổi dữ liệu của Sli Home. Trong trường hợp Sli Home 
                                phát hiện khách hàng có hành vi cố tình giả mạo, gian lận, phát tán thông tin cá nhân 
                                trái phép … Sli Home có quyền chuyển thông tin cá nhân của khách hàng cho các cơ quan 
                                có thẩm quyền để xử lý theo quy định của pháp luật.</li>
                            <li>Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ ba nào về thông 
                                tin cá nhân của Khách hàng khi không có sự cho phép hoặc đồng ý từ Khách hàng, 
                                trừ những trường hợp pháp luật có quy định khác.</li>
                            <li>Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của Khách hàng bao gồm thông tin hóa đơn, 
                                chứng từ kế toán số hóa tại khu vực dữ liệu trung tâm an toàn của Sli Home.</li>
                        </ul>
                    </div>
                    <div className="item-term-service">
                        <p className="title-term-service">6. Cơ chế tiếp nhận và giải quyết khiếu nại liên quan 
                        đến việc thông tin cá nhân khách hàng</p>
                        <p>Thành viên có quyền gửi khiếu nại về việc lộ thông tin các nhân cho bên thứ 3 đến Ban quản trị của Sli
                             Home đến địa chỉ Công ty hoặc qua email.</p>
                        <p>Email: welcome@slihome.com</p>
                        <p>Công ty có trách nhiệm thực hiện các biện pháp kỹ thuật, nghiệp vụ để xác minh các nội dung được phản ánh.</p>
                        <p>Thời gian xử lý phản ánh liên quan đến thông tin cá nhân khách hàng là 15 ngày.</p>
                    </div>

                </div>
                
	        </div>
            <SHFooter/>
        </div>
    )
}

export default SHPrivatePolicy
