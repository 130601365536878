import React from 'react';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import "../SHSignIn/SlihomeAuth.css";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SlihomeAPIClient } from '../Models/SlihomeAPI';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Open Sans",
  },
  typography:{
    fontWeight: "800",
  },
  typographyHeader:{
    color: '#484848',
    fontWeight: "bold",
    fontSize: "30px",
    fontFamily: "Open Sans",
  },
  typographyLink: {
    color: '#888888',
    fontWeight: "600",
  },
  typographyHint: {
    color: '#CECECE',
    fontWeight: "600",
    fontSize: "14px",
  },
  image: {
    backgroundImage: 'url(/images/Asset-2-1536x627.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderRadius: "0",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderColor: "#f02d4e !important"
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://slihome.com/">
        SLI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInSide() {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  function signUpHandler() {
    clearResult();
    let fullName = $('#fullName').val();
    let email = $('#email').val();
    let password = $('#password').val();
    let confirmPassword = $('#confirmPassword').val();
    let checkResult = checkSignUpContext(fullName, email, password, confirmPassword);
    if(checkResult.length === 0) {
      signUpRequest({
        username: email,
        password: password,
        userAttributes: {
          email: email,
          name: fullName
        }
      });
    }
    else{
      notifyInvalidField(checkResult);
    }
  }

  function notifyInvalidField(checkResult=[]) {
    checkResult.forEach(ele=>{
      switch(ele.type){
        case "fullName":
          $('#fullNameResultText').html(ele.checkResult);
          $('#fullNameResultHolder').fadeIn().removeClass("transparent");
          break;
        case "email":
          $('#emailResultText').html(ele.checkResult);
          $('#emailResultHolder').fadeIn().removeClass("transparent");
          break;
        case "emailExist":
          $('#emailResultText').html(ele.checkResult);
          $('#emailResultHolder').fadeIn().removeClass("transparent");
          $('#forgotPasswordHolder').removeClass("transparent");
          $('#forgotPasswordLink').prop('href', `/quen-mat-khau?email=${$('#email').val()}`)
          break;
        case "password":
          $('#passwordResultText').html(ele.checkResult);
          $('#passwordResultHolder').fadeIn().removeClass("transparent");
          break;
        case "confirmPassword":
          $('#confirmPasswordResultText').html(ele.checkResult);
          $('#confirmPasswordResultHolder').fadeIn().removeClass("transparent");
          break;
        default:
      }
    })
  }

  function signUpRequest(signUpContext){
    setLoading(true);
    SlihomeAPIClient.signUp(signUpContext)
    .then(response=>{
      if(response.data.CodeDeliveryDetails.DeliveryMedium==="EMAIL"){
        signUpSuccess({
          username: signUpContext.username,
          email: signUpContext.userAttributes.email,
          fullName: signUpContext.userAttributes.name,
        });
      }
      else {
        $('#signUpResultText').html("Lỗi không xác định. Liên hệ quản trị viên để được hỗ trợ");
        $('#signUpResultText').fadeIn().removeClass("transparent");
      }
    }).catch(err=>{
      if(err.response.data.code === "UsernameExistsException"){
        notifyInvalidField([{
          type:"emailExist",
          checkResult: "Email đã tồn tại"
        }])
      }
      else {
        $('#signUpResultText').html("Lỗi không xác định. Liên hệ quản trị viên để được hỗ trợ");
        $('#signUpResultText').fadeIn().removeClass("transparent");
      }
    }).finally(()=>{
      setLoading(false);
    });
  }

  function signUpSuccess(userContext){
    let greeting = `Xin chào ${userContext.fullName}`
    let content = `Bạn đã đăng ký tài khoản ${userContext.username} thành công! `;
    $('#signUpSuccessGreeting').html(greeting);
    $('#signUpSuccessContent').html(content);
    $('#main').addClass('blur');
    $('#signUpSuccessNotification').fadeIn(300);
  }

  function checkSignUpContext(fullName, email, password, confirmPassword) {
    let checkResult = []
    if(!fullName) {
      checkResult.push({
        type: "fullName",
        checkResult: "Vui lòng nhập đầy đủ họ tên"
      })
    }
    if(!email) {
      checkResult.push({
        type: "email",
        checkResult: "Vui lòng nhập email"
      })
    }
    else if(!validateEmail(email)) {
      checkResult.push({
        type: "email",
        checkResult: "Vui lòng nhập đúng định dạng email"
      })
    }
    if(!password) {
      checkResult.push({
        type: "password",
        checkResult: "Vui lòng nhập mật khẩu"
      })
    }
    else if(password.length < 8) {
      checkResult.push({
        type: "password",
        checkResult: "Mật khẩu chưa đủ dài (8 ký tự)"
      })
    }
    if(!confirmPassword) {
      checkResult.push({
        type: "confirmPassword",
        checkResult: "Vui lòng nhập lại mật khẩu"
      })
    }
    if(password&&confirmPassword&&password!==confirmPassword){
      checkResult.push({
        type: "confirmPassword",
        checkResult: "Mật khẩu không khớp"
      })
    }
    return checkResult;
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function clearResult(){
    $('.resultHolder').addClass('transparent');
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tham gia cùng Slihome</title>
        <meta name="description" content="Đăng ký để trải nghiệm nền tảng tìm trọ slihome ngay và nhận được nhiều ưu đãi hấp dẫn" />
      </Helmet>
      <Grid container id="signUpSuccessNotification" justify="center" spacing={4}>
        <Grid item xs>
          <img src="/icon/g94.png" alt="hình đăng ký thành công" style={{width: "100%"}}></img>
        </Grid>
        <Grid item xs>
          <Typography id="signUpSuccessGreeting" component="h4" variant="h4" className="gradient-text">
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography id="signUpSuccessContent" component="h5" variant="h5">
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            Vui lòng thực hiện xác thực tài khoản qua email đã được gửi đến hòm thư của bạn. Trong một số trường hợp, email này có thể bị hòm thư của bạn lọc vào thư mục spam.
          </Typography>
        </Grid>
        <Grid item xs>
          <Link href="/dang-nhap" className="gradient-text">
            <b> &#8678; </b> Quay lại trang đăng nhập
          </Link>
        </Grid>
      </Grid>
      <Grid container component="main" className={classes.root} id="main">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <a href="/">
              <img src="/images/cropped-imgpsh_fullsize_anim-192x192.png" alt="banner"/>
            </a>
            <Typography component="h1" className={classes.typographyHeader}>
              Đăng ký
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                id="fullName"
                label="Họ và tên"
                name="fullName"
                autoComplete="name"
                autoFocus
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="fullNameResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="fullNameResultText">bắt buộc</span>
                </Grid>
              </Grid>
              <TextField
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="emailResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="emailResultText">bắt buộc</span>
                </Grid>
                <Grid item id="forgotPasswordHolder" className="resultHolder transparent">
                  <Link href="/quen-mat-khau" className={classes.typographyLink} id="forgotPasswordLink">
                    Quên mật khẩu?
                  </Link>
                </Grid>
              </Grid>
              <TextField 
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="passwordResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="passwordResultText">bắt buộc</span>
                </Grid>
              </Grid>
              <TextField 
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Nhập lại mật khẩu"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="confirmPasswordResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="confirmPasswordResultText">bắt buộc</span>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="center">
                <Grid item xs={10}>
                  <Button
                    type="button"
                    id="submitButton"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="signin-btn"
                    style={{
                      "borderRadius": "10rem",
                      "height": "3rem",
                      "fontWeight": "bolder",
                      "fontFamily": "Open Sans",
                      "fontSize": "16px"
                    }}
                    onClick={signUpHandler}
                    disabled={loading}
                  >
                    {loading?<CircularProgress style={{color: "#FFFFFF"}}/>:"ĐĂNG KÝ"}
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <span id="signUpResultText" className="resultHolder transparent">
                    &nbsp;
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Link href="/dang-nhap" className="gradient-text">
                    <b> &#8678; </b> Quay lại trang đăng nhập
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}